import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetTenantAccess } from 'granite-admin/utils/auth-singleton';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';

import { deleteAllCookies, mainDomain } from 'common/utils';
import useWebSocket from 'common/useWebSocket';

export const useSignOut = () => {
  const { websocketInstance, closeConnection } = useWebSocket({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return () => {
    deleteAllCookies(mainDomain);
    resetTenantAccess();
    removeAuthToken();
    dispatch({ type: 'accounts/user/RESET_STATE' });
    if ( websocketInstance && websocketInstance.readyState === WebSocket.OPEN) {
      closeConnection(true);
    }
    if (navigate) navigate('/parent/login');
  };
};
