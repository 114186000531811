const ORGANISATION_EVENTS = {
  PROFILE_SAVE_SUCCESS: 'PROFILE_SAVE_SUCCESS',
  PROFILE_SAVE_FAILURE: 'PROFILE_SAVE_FAILURE',
  SETTINGS_FETCH_SUCCESS: 'SETTINGS_FETCH_SUCCESS',
  SETTINGS_FETCH_FAILURE: 'SETTINGS_FETCH_FAILURE',
  SETTINGS_SAVE_SUCCESS: 'SETTINGS_SAVE_SUCCESS',
  SETTINGS_SAVE_FAILURE: 'SETTINGS_SAVE_FAILURE',
  COMPANY_TYPES_FETCH_SUCCESS: 'COMPANY_TYPES_FETCH_SUCCESS',
  COMPANY_TYPES_FETCH_FAILURE: 'COMPANY_TYPES_FETCH_FAILURE',
};

export { ORGANISATION_EVENTS };