// Lib imports
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Image, Heading } from 'grommet';
import { StatusGood, Radial } from 'grommet-icons';
import Styled from 'styled-components';

// Core imports
import { useToast } from 'granite-admin/core/components/Toast';
import EventEmitter from 'granite-admin/utils/event-emitter';
import ONBOARDING_MESSAGES from 'granite-admin/messages/onBoarding';
import SplitLayout from 'granite-admin/core/components/SplitLayout';

// Application imports
// import { createOrganisationClicked } from 'granite-admin/organisations/controllers/organisation';
import { getCompanyTypes } from 'granite-admin/common/controllers/common';
import OnBoardingForm from './components/OnBoardingForm';
import { ONBOARDING_EVENTS } from 'granite-admin/organisations/controllers/constants';
import Onboarding from 'assets/onboarding-3.svg';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';

import { env } from 'granite-admin/env';

// eslint-disable-next-line
const StyledBox = Styled(Box)`
position:relative;
  &:after {
    content: "";
  background-color: #fff;
  position: absolute;
  width: 2px;
  height: 24px;
  left: 50%;
  top: calc(50% + 1px);
  transform:translateX(-50%);
  display: block;
  }
`;
const OnBoarding = ({ dispatch, navigate, userProfile, selectedOrganisation }) => {
  const { successToast, errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  // Define/Connect state
  const [companyTypesList, setCompanyTypesList] = useState([]);

  // Internal utils methods
  const goToDashboard = useCallback(() => navigate('/dashboard'), [navigate]);

  const projectName = env.REACT_APP_PROJECT_NAME || process.env.REACT_APP_PROJECT_NAME || 'RollCall';
  const msg = `Welcome to ${projectName}`;
  /**
   * Effect: subscribe and listen to Rx events
   * Cleanup: unsubscribe to Rx observable
   * Deps: eventEmitter, goToDashboard
   */
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        // case ONBOARDING_EVENTS.ONBOARDING_SUCCESS:
        //   dispatch({
        //     type: 'organisations/UPDATE_ORGANISATIONS',
        //     data: [event.data],
        //   });
        //   dispatch({
        //     type: 'organisations/UPDATE_SELECTED_ORGANISATION',
        //     data: event.data,
        //   });
        //   successToast(ONBOARDING_MESSAGES.ONBOARDING_SUCCESS);
        //   window.location.href = '/dashboard';
        //   break;
        case ONBOARDING_EVENTS.ONBOARDING_FAILURE:
          errorToast(ONBOARDING_MESSAGES.ONBOARDING_FAILURE);
          break;
        case ONBOARDING_EVENTS.COMPANY_TYPES_FETCH_SUCCESS:
          setCompanyTypesList(event.data.possible_values);
          break;
        case ONBOARDING_EVENTS.COMPANY_TYPES_FETCH_FAILURE:
          errorToast('Failed to fetch company types');
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, goToDashboard, dispatch, successToast, errorToast]);

  useEffect(() => {
    getCompanyTypes(eventEmitter);
  }, [eventEmitter]);

  return (
    <Box background="white">
      <SplitLayout
        mainContent={
          <Box width="large" flex="grow">
            <Heading level={3}>Tell Us About Your Company</Heading>
            <OnBoardingForm
              // onSubmit={createOrganisationClicked}
              // eventEmitter={eventEmitter}
              companyTypesList={companyTypesList}
              userProfile={userProfile}
              navigate={navigate}
              dispatch={dispatch}
              selectedOrganisation={selectedOrganisation}
            />
          </Box>
        }
        sideContent={
          <Box flex>
            <Box>
              <Image src={Onboarding} fit="contain" />
            </Box>
            <Box alignSelf="center" margin={{ top: 'xlarge' }}>
              <Box direction="row" gap="medium" flex={{ shrink: 0 }}>
                <StyledBox>
                  <StatusGood color="white" />
                </StyledBox>
                <Heading level={4} color="white">
                  {msg}
                </Heading>
              </Box>
              <Box direction="row" gap="medium">
                <Radial color="white" />
                <Heading level={4} color="white">
                  Tell Us About Your Company
                </Heading>
              </Box>
            </Box>
          </Box>
        }
      />
    </Box>
  );
};

OnBoarding.propTypes = {
  dispatch: PropTypes.func,
  navigate: PropTypes.object,
  userProfile: PropTypes.object,
  selectedOrganisation: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  userProfile: UserDucks.profile(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
