// Lib Imports
import React from 'react';
import { Box, Button } from 'grommet';
import { Formik } from 'formik';
import * as Yup from 'yup';

// CORE Imports
import FormField from 'granite-admin/core/components/CustomFormField';
import OtpInput from 'granite-admin/core/components/OtpInput';
// Application Imports

const VerificationForm = ({ eventEmitter, otpSubmit, type, location, brandColor }) => {
  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Otp is required'),
  });

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      let obj = {
        [type]: location.state?.email,
        otp: values.token,
      };
      await otpSubmit(eventEmitter, obj, location.state);
    } catch (e) {
      if (e.errors) setErrors(e.errors);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        token: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting,isValid,dirty }) => (
        <form onSubmit={handleSubmit}>
          <FormField name="token" label="One Time Password" error={touched.token && errors.token}>
            <OtpInput
              name="token"
              onChange={otp => setFieldValue('token', otp)}
              onBlur={handleBlur}
              value={values.token}
              numInputs={6}
            />
          </FormField>
          <Box fill="horizontal" direction="column" elevation="none" justify="between" pad="none">
            <Button
              type="submit"
              alignSelf="start"
              margin={{ vertical: 'small' }}
              label={isSubmitting ? 'Veriyfing ...' : 'Verify'}
              disabled={isSubmitting || !(isValid && dirty)}
              primary
              color={brandColor || 'secondary'}
            />
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default VerificationForm;
