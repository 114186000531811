// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';

// Application imports
import OrganisationApi from 'companySettings/gateways/organisation-api';
import { ORGANISATION_EVENTS } from './constants';

async function getMasterPreferences() {
  try {
    const list = await OrganisationApi.getMasterConfigs('ORGANISATION_SETTING');
    return list;
  } catch (error) {
    throw new GraniteError(error.errors);
  }
}

async function getSettings(eventEmitter, orgId) {
  try {
    const response = await OrganisationApi.getSettings({}, orgId);
    return response;
  } catch (error) {
    eventEmitter.emit(ORGANISATION_EVENTS.SETTINGS_FETCH_FAILURE);
    throw error;
  }
}

export { getMasterPreferences, getSettings };