import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Text, TextArea, Button, CheckBox, Heading, Grid, Accordion, AccordionPanel, Anchor } from 'grommet';
import { Formik } from 'formik';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

// import DatePicker from 'granite-admin/core/components/DatePicker';
import DatePicker from 'common/DatePicker';
import FormField from 'granite-admin/core/components/FormField';
import Select from 'granite-admin/core/components/Select';
import { useToast } from 'granite-admin/core/components/Toast';

import { fetchTransportData, getRouteSmsBalance, messageAllRoutes } from 'adminTransport/controllers/transport';
import { parseRouteList } from '../utils';
import ConfirmLayer from './ConfirmLayer';
import { viewDateFormat, getFormatedDate } from 'common/CommonFunction';
import TransportConstants from 'adminTransport/controllers/constants';

const StyledAccordionPanel = styled(AccordionPanel)`
  background: white;
  & h5 {
    font-size: 14px;
  }
  & svg {
    height: 15px;
    color: #868686;
  }
`;

const commonStyle = {
  direction: 'row',
  gap: 'small',
  align: 'center',
  background: '#ff00004d',
  pad: '6px',
};

const recipientsColor = '#FFA500';
const availableMsgColor = '#00A300';
const byteSize = 134;

const MessageRoutes = ({ eventEmitter, setRouteMessage, userPreferenceData }) => {
  const [currentDate, setCurrentDate] = useState(getFormatedDate(new Date(), userPreferenceData?.userTimezone));
  const [events, setEvents] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [routeEvent, setRouteEvent] = useState({});
  const [confirmLayer, setConfirmLayer] = useState(false);
  const [confirmData, setConfirmData] = useState('');
  const [selectedEventId, setSelectedEventId] = useState([]);
  const [smsBalanceCount, setSmsBalanceCount] = useState(0);
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [selectedBoardedChildren, setSelectedBoardedChildren] = useState('ALL');
  const [selectedMsgType, setSelectedMsgType] = useState(userPreferenceData?.routeMessageOption);
  const [messageSize, setMessageSize] = useState(0);
  const [isCheckboxDisable, setIsCheckboxDisable] = useState(false);

  const { errorToast } = useToast();

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case TransportConstants.TRANSPORT_EVENTS.FETCH_ROUTE:
          setEvents(event?.data);
          setRouteList(parseRouteList(event?.data));
          break;
        case TransportConstants.TRANSPORT_EVENTS.FETCH_SUCCESS_ROUTE_SMS_BALANCE:
          setSmsBalanceCount(event?.data?.sms_balance);
          break;
        case TransportConstants.TRANSPORT_EVENTS.FETCH_FAILURE_ROUTE_SMS_BALANCE:
          errorToast(event?.data?.title || 'Failed to get SMS Balance count');
          break;
        case TransportConstants.TRANSPORT_EVENTS.FETCH_SUCCESS_RECIPENTS_COUNT:
          setRecipientsCount(event?.data?.recipients_count || 0);
          setIsCheckboxDisable(false);
          break;
        case TransportConstants.TRANSPORT_EVENTS.FETCH_FAILURE_RECIPENTS_COUNT:
          errorToast(event?.data?.title || 'Failed to get Recipents count');
          setIsCheckboxDisable(false);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, routeList]);

  const isSmsType = useMemo(() => selectedMsgType?.toLowerCase() === 'sms', [selectedMsgType]);

  const lowBalance = useMemo(() => isSmsType && smsBalanceCount <= 0, [smsBalanceCount, isSmsType]);

  useEffect(() => {
    fetchTransportData(eventEmitter, currentDate, 'undefined');
  }, [eventEmitter, currentDate]);

  useEffect(() => {
    if (isSmsType) getRouteSmsBalance({ eventEmitter });
  }, [isSmsType]);

  useEffect(() => {
    if (selectedEventId?.length && selectedBoardedChildren) {
      setIsCheckboxDisable(true);
      messageAllRoutes({
        eventEmitter,
        confirmData: {
          message: '',
          children_who_have_boarded: selectedBoardedChildren,
          message_type: selectedMsgType,
        },
        selectedEventId,
        recipient_count: true,
        sent_sms: true,
      });
    } else {
      setRecipientsCount(0);
      setIsCheckboxDisable(false);
    }
  }, [eventEmitter, selectedBoardedChildren, selectedEventId, selectedMsgType]);

  const onRoutesCheckAll = (event, routeList) => {
    let sortedList = {};
    if (event) {
      for (let i = 0; i < events?.length; i++) {
        let route = events[i];
        let groupName = route.groupName;
        if (sortedList[groupName]) sortedList[groupName].push(route.pk);
        else sortedList[groupName] = [route.pk];
      }
    } else {
      for (let i = 0; i < events?.length; i++) {
        let route = events[i];
        let groupName = route.groupName;
        if (sortedList[groupName]) sortedList[groupName].push();
        else sortedList[groupName] = [];
      }
    }
    setRouteEvent(sortedList);
  };

  useEffect(() => {
    let allSelectedEventId = [];
    let listEvents = Object.keys(routeEvent).map(key => {
      return routeEvent[key];
    });
    listEvents.map(item => {
      for (let x of item) {
        allSelectedEventId.push(x);
      }
    });
    setSelectedEventId(allSelectedEventId);
  }, [routeEvent, events]);

  const checkedState = (routeEvent, events) => {
    let selectedEvents = [];
    Object.values(routeEvent).map(item => {
      for (let x of item) selectedEvents.push(x);
    });
    if (selectedEvents.length === events.length) return true;
    else return false;
  };

  const onCheckAll = (event, data, group) => {
    if (event) {
      setRouteEvent(prev => ({
        ...prev,
        [group]: data.map(i => i.pk),
      }));
    } else {
      setRouteEvent(prev => ({
        ...prev,
        [group]: [],
      }));
    }
  };

  const onCheck = (event, value, group) => {
    if (event) {
      setRouteEvent(prev => {
        if (prev[group]) return { ...prev, [group]: [...prev[group], value] };
        else return { ...prev, [group]: [value] };
      });
    } else {
      setRouteEvent(prev => ({
        ...prev,
        [group]: [...prev[group].filter(item => item !== value)],
      }));
    }
  };
  const onSubmit = useCallback(
    values => {
      if (!userPreferenceData?.triggerEmails) {
        errorToast('Emails are currently turned off for your school, please contact support to turn them on.');
        return;
      }
      setConfirmLayer(true);
      setConfirmData(values);
    },
    [userPreferenceData, errorToast],
  );

  const validationSchema = Yup.object().shape({
    message: Yup.string().required('Please enter the message'),
  });

  const calculatMessageByteSize = useCallback(message => {
    const gsm7bitChars =
      '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞ\x1BÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà';
    const gsm7bitExtendedChars = '^{}\\[~]|€';
    let byteSize = 0;

    for (let char of message) {
      if (gsm7bitChars.includes(char)) {
        byteSize += 1;
      } else if (gsm7bitExtendedChars.includes(char)) {
        byteSize += 2;
      } else {
        const blobSize = new Blob([char]).size;
        byteSize += blobSize;
      }
    }
    return byteSize;
  }, []);

  return (
    <Box>
      <Formik
        initialValues={{
          message: '',
          children_who_have_boarded: 'ALL',
          message_type: userPreferenceData?.routeMessageOption,
        }}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, setFieldValue, errors, touched, setFieldError }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Box direction="row" justify="between" width="100%">
                  <Box direction="row" gap="small" align="center">
                    <DatePicker
                      disableInput={true}
                      name="date"
                      initialDate={currentDate}
                      value={currentDate}
                      onChange={date => {
                        setCurrentDate(date);
                        setRouteEvent({});
                      }}
                      customFormat="yyyy-MM-dd"
                      viewDateFormat={viewDateFormat}
                      // bounds={[
                      //   `${getFormatedDate(new Date(), userPreferenceData.userTimezone)} 00:00:00`,
                      //   '2099-12-31 00:00:00',
                      // ]}
                      // bounds={[getFormatedDate(new Date(), userPreferenceData.userTimezone), '2099-12-31']}
                      isconvertBoundsToTimeZone={false}
                      // bounds={[new Date().toISOString(), '2099-12-31']}
                    />
                    <Text>Select date to choose routes scheduled for another day.</Text>
                  </Box>
                  <Box gap="small" justify="center" align="end" width="50%">
                    {isSmsType && lowBalance && (
                      <Box {...commonStyle}>
                        <Text>Your message balance is below the threshold, please top up to send SMS messages.</Text>
                      </Box>
                    )}
                    {isSmsType && messageSize > byteSize && (
                      <Box {...commonStyle}>
                        <Text>
                          Attention: Your message exceeds the size limit and will incur additional charges if sent as
                          is. Reduce the length to avoid additional charge.
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Grid pad={{ top: 'small' }} height="100%" columns={['35%', 'flex']} gap="small">
                  <Box>
                    <Heading level="4" style={{ color: '#100e0eb5' }}>
                      Select Routes to Message
                    </Heading>
                    <Accordion
                      animate={true}
                      multiple
                      activeIndex={activeIndex}
                      onActive={newActiveIndex => setActiveIndex(newActiveIndex)}
                    >
                      <StyledAccordionPanel
                        label={
                          <Box direction="row" gap="small" justify="between" pad="small" width="100%">
                            <Text style={{ fontWeight: '600', color: '#100e0eb5' }}>Message all Routes</Text>
                            <CheckBox
                              size="small"
                              checked={checkedState(routeEvent, events)}
                              onClick={e => e.stopPropagation()}
                              onChange={e => onRoutesCheckAll(e.target.checked, routeList)}
                              disabled={isCheckboxDisable}
                            />
                          </Box>
                        }
                      >
                        <Box margin={{ vertical: 'small' }} background="white">
                          <Accordion border={{ side: 'bottom', size: '1px', color: '#ddd' }} multiple>
                            {routeList
                              ?.sort((a, b) => Object.keys(a)[0].localeCompare(...Object.keys(b)))
                              ?.map((i, index) => (
                                <StyledAccordionPanel
                                  key={index}
                                  label={
                                    <Box direction="row" gap="small" justify="between" pad="small" width="100%">
                                      <Text style={{ fontWeight: '600', color: '#100e0eb5' }}>{Object.keys(i)}</Text>
                                      <CheckBox
                                        size="small"
                                        checked={
                                          routeEvent[Object.keys(i)]
                                            ? routeEvent[Object.keys(i)].length === Object.values(i)[0].length
                                              ? true
                                              : false
                                            : false
                                        }
                                        onClick={e => e.stopPropagation()}
                                        onChange={e =>
                                          onCheckAll(e.target.checked, Object.values(i)[0], ...Object.keys(i))
                                        }
                                        disabled={isCheckboxDisable}
                                      />
                                    </Box>
                                  }
                                >
                                  {Object.values(i)[0].map((i, index) => (
                                    <Box
                                      key={index}
                                      border={{ side: 'bottom', size: '1px', color: '#ddd' }}
                                      direction="row"
                                      gap="small"
                                      pad={{ horizontal: 'small', vertical: 'small' }}
                                      justify="between"
                                      width="100%"
                                    >
                                      <Text style={{ fontWeight: '600', color: '#100e0eb5' }}>{i.route}</Text>
                                      <CheckBox
                                        size="small"
                                        name={`${index}.checkedItem`}
                                        checked={
                                          routeEvent[i.groupName] ? routeEvent[i.groupName].includes(i.pk) : false
                                        }
                                        onChange={e => onCheck(e.target.checked, i.pk, i.groupName)}
                                        disabled={isCheckboxDisable}
                                      />
                                    </Box>
                                  ))}
                                </StyledAccordionPanel>
                              ))}
                          </Accordion>
                        </Box>
                      </StyledAccordionPanel>
                    </Accordion>
                  </Box>
                  <Box>
                    <Box direction="row">
                      <Box direction="column" width="50%">
                        <Heading level="4" style={{ color: '#100e0eb5' }}>
                          Message Content
                        </Heading>
                      </Box>
                      <Box direction="column" width="50%" alignSelf="end" style={{ marginBottom: '13px' }}>
                        <Button
                          primary
                          type="submit"
                          label={values.message_type === 'SMS' ? 'SEND SMS MESSAGE' : 'SEND EMAIL MESSAGE'}
                          alignSelf="end"
                          color="accent-1"
                          onClick={() => {}}
                          disabled={recipientsCount <= 0 || lowBalance}
                        />
                      </Box>
                    </Box>
                    <Box background="white">
                      <TextArea
                        fill
                        rows="4"
                        name="message"
                        placeholder={'Message to Parents'}
                        values={values.message}
                        onChange={value => {
                          if (isSmsType) {
                            let bytesSize = calculatMessageByteSize(value.target.value);
                            setMessageSize(bytesSize);
                          }
                          setFieldValue('message', value.target.value);
                        }}
                      />
                      {touched.message && errors.message && <Text color="red"> {errors.message}</Text>}
                    </Box>
                    <Box width="100%" direction="row" gap="medium">
                      <Box width="70%">
                        <FormField
                          name="children_who_have_boarded"
                          label={<Box pad={{ bottom: 'small' }}> Select Message Options</Box>}
                        >
                          <Select
                            name="children_who_have_boarded"
                            labelKey="value"
                            value={values.children_who_have_boarded}
                            options={[
                              { pk: 'ALL', value: 'Send to all parents with students booked on this route' },
                              { pk: 'ON', value: 'Only send to parents with students who have boarded' },
                              { pk: 'ALLOCATED', value: 'Only send to parents with students who have not yet boarded' },
                            ]}
                            valueKey={{ key: 'pk', reduce: true }}
                            onChange={({ value }) => {
                              setSelectedBoardedChildren(value);
                              setFieldValue('children_who_have_boarded', value);
                            }}
                            isClearable={false}
                          />
                        </FormField>
                        <Text color={recipientsColor}>Number of Recipients : {recipientsCount}</Text>
                      </Box>
                      <Box width="30%">
                        <FormField name="message_type" label={<Box pad={{ bottom: 'small' }}> Message Type</Box>}>
                          <Select
                            name="message_type"
                            labelKey="value"
                            value={values.message_type}
                            options={[
                              { pk: 'SMS', value: 'SMS' },
                              { pk: 'EMAIL', value: 'EMAIL' },
                            ]}
                            valueKey={{ key: 'pk', reduce: true }}
                            onChange={({ value }) => {
                              setFieldValue('message_type', value);
                              setSelectedMsgType(value);
                            }}
                            isClearable={false}
                            disabled={userPreferenceData?.routeMessageOption === 'EMAIL'}
                          />
                        </FormField>
                        {isSmsType && <Text color={availableMsgColor}>Available Message : {smsBalanceCount}</Text>}
                      </Box>
                    </Box>
                    {isSmsType && (
                      <Link
                        target="_blank"
                        to={'https://rollcallmarketplace.com.au/collections/sms-credit-top-up'}
                        style={{ alignSelf: 'end' }}
                      >
                        <Anchor
                          as="span"
                          label="Click to top up message balance"
                          size="medium"
                          margin={{ vertical: 'small' }}
                          color={'secondary'}
                        />
                      </Link>
                    )}
                  </Box>
                </Grid>
              </form>
            </>
          );
        }}
      </Formik>
      {confirmLayer && (
        <ConfirmLayer
          setConfirmLayer={setConfirmLayer}
          currentDate={currentDate}
          confirmData={confirmData}
          events={events}
          setRouteMessage={setRouteMessage}
          errorToast={errorToast}
          selectedEventId={selectedEventId}
          recipientsCount={recipientsCount}
          isSmsType={isSmsType}
          messageSize={messageSize}
          byteSize={byteSize}
        />
      )}
    </Box>
  );
};

MessageRoutes.propTypes = {
  eventEmitter: PropTypes.any,
  setRouteMessage: PropTypes.func,
  userPreferenceData: PropTypes.object,
};

export default MessageRoutes;
