class RouteMessageLogsEntity {
  constructor(data) {
    this.data = data?.route.map(i => ({
      messageDatetime: data?.message_datetime,
      message: data?.message,
      sentBy: data?.sent_by,
      route: i?.name,
      routeId: i?.pk,
      recipients: data?.recipients || [],
      affectedStudents: data?.affected_students || 0,
      sendOptions: data?.send_options,
      routeName: i?.route?.name,
      busDate: data?.bus_date,
    }));
  }
}

export { RouteMessageLogsEntity };
