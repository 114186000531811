import { Table, TableBody, TableCell, TableHeader } from 'grommet';
import styled from 'styled-components';

const TOPNAVBAR_EVENTS = {
  FETCH_LOGS_SUCCESS: 'FETCH_LOGS_SUCCESS',
  FETCH_LOGS_FAILURE: 'FETCH_LOGS_FAILURE',
};

// Your custom styled components
const StyledTable = styled(Table)`
  border: 1px solid #eaeff0;
`;
const StyledTableHeader = styled(TableHeader)`
  font-size: small;
  padding: 6px;
  color: #000000;
`;
const StyledTableBody = styled(TableBody)`
  font-size: small;
  border-color: #eaeff0;
`;
const StyledTableCell = styled(TableCell)`
  padding: 8px 5px;
  border: 1px solid #eaeff0;
  min-width: ${({ width }) => width || '50px'}; // Use a template literal
`;

const MARKERPLACE_MESSAGING_DATA = [
  {
    name: 'messaging',
    label: 'Messaging',
    icon:
      '<svg version="1.0" xmlns="http://www.w3.org/2000/svg"\r\n width="13.000000pt" height="13.000000pt" viewBox="0 0 512.000000 512.000000"\r\n preserveAspectRatio="xMidYMid meet">\r\n\r\n<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"\r\nfill="#ffffff" stroke="none">\r\n<path d="M521 4784 c-169 -45 -301 -180 -346 -351 -23 -87 -22 -2700 0 -2788\r\n45 -172 179 -305 352 -350 44 -12 120 -15 325 -15 l267 0 3 -429 c3 -416 4\r\n-430 24 -457 49 -67 130 -91 195 -60 19 9 295 225 614 481 l580 464 1000 1\r\nc1088 0 1053 -2 1167 56 71 36 159 123 197 193 64 121 61 41 61 1511 0 1167\r\n-2 1343 -15 1395 -45 172 -179 305 -352 350 -85 22 -3989 21 -4072 -1z m4045\r\n-330 c15 -11 37 -33 48 -48 21 -27 21 -30 21 -1365 l0 -1338 -29 -37 c-53 -70\r\n14 -66 -1125 -66 -636 0 -1039 -4 -1055 -10 -14 -5 -239 -180 -501 -390 -261\r\n-209 -477 -380 -479 -380 -3 0 -7 153 -8 339 -3 325 -4 341 -24 367 -11 15\r\n-33 37 -48 48 -26 20 -43 21 -406 26 -363 5 -380 6 -406 26 -15 11 -37 33 -48\r\n48 -21 27 -21 32 -24 1339 -1 722 0 1326 3 1344 7 40 49 91 90 109 25 11 377\r\n13 1997 11 l1967 -2 27 -21z"/>\r\n<path d="M1215 3506 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 26\r\n-8 449 -10 1354 -8 1312 3 1317 3 1344 24 53 39 69 71 69 134 0 63 -16 95 -69\r\n134 -27 21 -31 21 -1354 23 -1088 2 -1332 0 -1357 -11z"/>\r\n<path d="M1215 2866 c-67 -29 -105 -106 -91 -181 9 -47 59 -102 104 -115 25\r\n-8 257 -10 714 -8 665 3 677 3 704 24 53 39 69 71 69 134 0 63 -16 95 -69 134\r\n-27 21 -38 21 -714 23 -556 2 -693 0 -717 -11z"/>\r\n</g>\r\n</svg>',
    extra_data: {
      emitter: 'MESSAGING',
      message: 'Messaging',
      btncolor: '#204879',
    },
  },
  {
    name: 'marketplace',
    label: 'Marketplace',
    icon:
      '<svg version="1.0" xmlns="http://www.w3.org/2000/svg"\r\n width="15.000000pt" height="15.000000pt" viewBox="0 0 512.000000 512.000000"\r\n preserveAspectRatio="xMidYMid meet">\r\n\r\n<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"\r\nfill="#ffffff" stroke="none">\r\n<path d="M957 4460 c-113 -36 -219 -130 -262 -232 -8 -21 -70 -263 -137 -540\r\nl-122 -503 -1 -135 c0 -116 3 -145 23 -205 33 -97 84 -191 137 -252 l45 -51 0\r\n-764 c0 -827 0 -814 55 -917 33 -60 114 -140 173 -170 104 -53 36 -51 1689\r\n-51 1083 0 1548 3 1585 11 157 33 293 170 327 327 8 37 11 287 11 809 l0 755\r\n45 50 c59 65 129 207 150 303 13 55 16 105 13 183 -4 94 -20 169 -127 611 -78\r\n323 -132 524 -149 559 -32 66 -101 140 -162 175 -104 60 -22 57 -1700 56\r\n-1470 0 -1537 -1 -1593 -19z m3191 -870 c124 -512 132 -568 95 -651 -97 -216\r\n-379 -219 -474 -5 -12 29 -35 191 -78 551 -33 281 -63 522 -66 537 l-4 28 162\r\n3 c89 2 182 3 207 2 l44 0 114 -465z m-2653 433 c-3 -16 -33 -257 -66 -538\r\n-64 -540 -68 -559 -128 -627 -68 -78 -196 -105 -286 -63 -54 26 -113 87 -138\r\n144 -36 81 -29 133 87 631 59 250 108 461 111 468 3 9 55 12 214 12 l211 0 -5\r\n-27z m855 -478 c0 -325 -4 -522 -11 -555 -23 -107 -108 -189 -220 -211 -47 -9\r\n-66 -8 -114 6 -106 31 -195 149 -195 261 0 32 103 904 115 972 l6 32 209 0\r\n210 0 0 -505z m845 473 c3 -18 30 -242 61 -498 36 -306 53 -480 49 -510 -14\r\n-92 -81 -182 -164 -218 -20 -8 -65 -15 -101 -16 -110 0 -194 56 -244 163 -20\r\n45 -21 61 -24 579 l-3 532 210 0 210 0 6 -32z m-1472 -1569 c32 -21 95 -50\r\n140 -66 75 -26 94 -28 222 -28 127 0 147 3 213 27 82 30 164 76 223 126 l39\r\n34 63 -48 c126 -96 267 -144 423 -144 160 0 319 57 429 154 l50 43 68 -53\r\nc119 -93 258 -144 394 -144 l73 0 0 -640 0 -640 -1495 0 -1495 0 0 640 0 640\r\n68 0 c136 0 284 56 397 148 l60 49 35 -30 c19 -17 61 -47 93 -68z"/>\r\n</g>\r\n</svg>',
    extra_data: {
      emitter: 'MARKETPLACE',
      message: 'clicked on market place',
      btncolor: '#7266ba',
    },
  },
];

export {
  TOPNAVBAR_EVENTS,
  StyledTable,
  StyledTableHeader,
  StyledTableBody,
  StyledTableCell,
  MARKERPLACE_MESSAGING_DATA,
};
