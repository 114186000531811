// Core imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';

import NotificationEntity from 'granite-admin/common/entities/notification';

async function fetchNotifications(page, is_read) {
  try {
    const payload = { params: { page, is_read } };
    const { data } = await GraniteAPIClient().get(`/notifications/`, payload);
    let list = [];
    const currentTimestamp = Date.now();
    data.results.forEach(item => {
      if (item.extra_data?.expires_timestamp && item.extra_data?.expires_timestamp * 1000 > currentTimestamp)
        list.push({ ...new NotificationEntity(item), isExpired: false });
      else list.push({ ...new NotificationEntity(item), isExpired: true });
    });
    return { list, nextPage: data.next_page };
  } catch (err) {
    console.log(err);
    throw new GraniteError(err.errors);
  }
}

export { fetchNotifications };
