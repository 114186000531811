// Core Imports
/* eslint-disable */
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import GraniteError from 'granite-admin/utils/granite-error';
import { getAuthToken, getOrganisation, getTenantAccess } from 'granite-admin/utils/auth-singleton';
import { env } from 'granite-admin/env';

import {
  TransportListEntity,
  BusAbsentee,
  BusChange,
  StudentBusGroup,
  updateManifestEntity,
} from 'adminTransport/entities/transport';

const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
// const url = process.env.REACT_APP_ROLLCALL_BASE_URL;
const logsLambda = process.env.REACT_APP_BUS_CHANGES_LAMBDA;
const downloadTransportReports = process.env.REACT_APP_TRANSPORT_REPORTS_DOWNLOAD_LAMBDA;
const manifestLambda = process.env.REACT_APP_TRANSPORT_MANIFEST;

const mapToSnakeCaseForAbsence = data => {
  const { values, currentRoute, userProfile, parents } = data;
  let payload = {};
  if (parents) {
    payload = {
      parent: {
        pk: parents?.pk,
      },
    };
  }
  return {
    // if(userProfile) {
    //   admin: {
    //     pk: userProfile.pk;
    //   }
    // },
    ...payload,
    student: {
      pk: values.student,
    },
    absence_type: values.absenceType,
    start_date: values.fromDate,
    end_date: values.toDate,
    reason: values.reason,
    // route_event_id: currentRoute?.eventId,
    route_event_student_booking_ids: values.rowChecked,
  };
};
const mapToSnakeCaseForEmergency = data => {
  const { values, currentGroup, currentRoute, studentsList, driver } = data;
  return {
    email: values?.email,
    route_id: currentRoute?.eventId,
    route_events_id: currentGroup?.pk,
    driver_name: driver ? driver : 'No Driver',
    route_name: currentRoute?.eventName,
    emergency: true,
    students_on_bus: studentsList,
  };
};

const mapToSnakeCaseForAddTraveller = data => {
  const { values, currentEventId, currentDate, userProfile, is_traveler, change_bus, booking_ids } = data;
  return {
    route_booking_data: {
      created_by_id: userProfile.pk,
      route_id: currentEventId,
      assign_students: values?.student?.map(i => ({
        pk: i,
        stop_pk: values?.stop?.pk !== 'nostop' ? values.stop?.pk : 0,
      })),
      list_dates: [currentDate],
      is_traveler,
      change_bus,
      booking_ids,
    },
  };
};

const mapToSnakeCaseForMessageAllRoutes = data => {
  const { confirmData, selectedEventId, ...rest } = data;
  return {
    route_event_id: selectedEventId,
    message: confirmData?.message,
    children_who_have_boarded: confirmData?.children_who_have_boarded,
    route_message_option: confirmData?.message_type,
    ...rest,
  };
};

const mapToSnakeCaseForAssignNewRouteToStudent = data => {
  const { rowData, route, stop } = data;
  return {
    route_booking_pk: rowData?.routeEventStudentBookingId,
    Student: {
      pk: rowData?.pk,
    },
    route_event_id: route?.pk,
    stop: {
      pk: stop?.pk,
    },
  };
};

const mapToSnakeCaseForChangeLogs = data => {
  const {
    studentIds,
    values,
    newStop,
    currentGroup,
    newRoute,
    currentDate,
    userPreferenceData,
    action,
    parents,
    isParentBooking = false,
  } = data;
  if (isParentBooking) {
    return {
      student_ids: studentIds,
      bus_audits: currentDate?.map(i => ({
        student_pk: values?.student,
        route_group: {
          pk: currentGroup?.pk,
          name: currentGroup?.groupName,
        },
        old_stop: {
          pk: values?.oldStop?.pk ?? 0,
          name: values?.oldStop?.name ?? 'No Stop',
        },
        new_stop: {
          pk: newStop?.pk,
          name: newStop?.name,
        },
        old_route_event: {
          pk: values?.oldRoute?.pk ?? 0,
          name: values?.oldRoute?.eventName ?? 'No Bus',
        },
        new_route_event: {
          pk: newRoute?.pk ?? 0,
          name: newRoute?.eventName ?? '',
        },
        booking_date: i,
        action: action,
        parent_email: parents?.email,
      })),
    };
  } else
    return {
      student_ids: studentIds,
      bus_audits: values?.map(i => ({
        student_pk: i.student,
        route_group: {
          pk: currentGroup?.pk,
          name: currentGroup?.groupName,
        },
        old_stop: {
          pk: i?.oldStop?.pk ?? 0,
          name:
            i?.oldStop?.name === 'NO STOP ALLOCATED' || i?.oldStop?.name === ''
              ? 'No Stop'
              : i?.oldStop?.name ?? 'No Stop',
        },
        new_stop: {
          pk: newStop?.pk === 'nostop' || newStop?.pk === null ? 0 : newStop === undefined ? 0 : newStop?.pk,
          name:
            newStop?.pk === 'nostop' || newStop?.pk === 0 || newStop?.pk === null
              ? 'No Stop'
              : newStop === undefined
              ? ''
              : newStop?.name,
        },
        old_route_event: {
          pk: i?.oldRoute?.pk ?? 0,
          name: i?.oldRoute?.eventName ?? '',
        },
        new_route_event: {
          pk: newRoute?.pk ?? 0,
          name: newRoute?.eventName ?? '',
        },
        booking_date: currentDate,
        action: action,
        admin_email: userPreferenceData?.email,
        parent_email: parents?.email,
      })),
    };
};

async function gettransportlist(params) {
  const payload = { params: { ...params } };
  try {
    const listArray = [
      {
        name: 'Harry',
        status: 'active',
        stop: 'uk',
        scanOn: '10:30',
        scanOff: '12:30',
        exception: 'working',
      },
      {
        name: 'Harry',
        status: 'active',
        stop: 'uk',
        scanOn: '10:30',
        scanOff: '12:30',
        exception: 'working',
      },
    ];

    if (!params.export) {
      const list = listArray ? listArray.map(item => new TransportListEntity({ mapValues: true, ...item })) : [];
      return { list, count: listArray.length };
    } else {
      return;
    }
  } catch (error) {
    console.log(error);
  }
}

async function getTransportData(params) {
  const { data } = await GraniteAPIClient(url).get(`/route-events/`, params);
  const transportData = data
    ? data.results.map(item => {
        const pk = item.pk;
        const date = item.route_date;
        const route_name = item.route_name;
        const eventId = item.route?.pk;
        const eventColor = item.route?.route_color;
        const eventName = item.route?.name;
        const groupName = item.route?.route_group?.group_name;
        const groupId = item.route?.route_group?.pk;
        const routeType = item?.route?.route_type;
        const {
          school_check: schoolCheck,
          supervisor_check: supervisorCheck,
          transport_medium_check: busCheck,
          supervisor_check_datetime,
          supervisor_check_off_datetime,
          transport_medium_check_datetime,
          school_check_datetime,
          driver_login_datetime,
          school_check_off_datetime,
          teacher,
          driver,
          supervisor,
        } = item;
        return {
          pk,
          date,
          eventId,
          eventColor,
          eventName,
          groupName,
          groupId,
          routeType,
          route_name,
          schoolCheck,
          supervisorCheck,
          busCheck,
          supervisor_check_datetime,
          supervisor_check_off_datetime,
          transport_medium_check_datetime,
          school_check_datetime,
          school_check_off_datetime,
          driver_login_datetime,
          teacher,
          driver,
          supervisor,
        };
      })
    : [];
  return transportData;
}

async function getDriverData(route_event_id) {
  const { data } = await GraniteAPIClient(url).get(`/route-events/${route_event_id}/`);
  return data;
}

async function getBusAbsenteeData(currentDate) {
  try {
    const { data } = await GraniteAPIClient(url).get(
      `/absence/?absence_date=${currentDate}&ordering=student__first_name&page_size=100000`,
    );
    const list = data ? data.results?.map(item => new BusAbsentee(item)) : [];
    return list;
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function getBusChangeData(currentDate) {
  try {
    const { data } = await GraniteAPIClient(url).get(`/bus/audit/?date=${currentDate}&page_size=100000`);
    const list = data ? data?.data?.map(item => new BusChange(item)) : [];
    return list;
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function fetchTransportStudentData(payload) {
  try {
    const { data } = await GraniteAPIClient(url).post(`/load-student-list/`, payload);
    return data;
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function getStudentBusGroupData(value) {
  const { data } = await GraniteAPIClient(url).get(
    `route-event-student-booking/?student_id=${value.student}&from_date=${value.fromDate}&to_date=${value.toDate}&ordering=route_event_id__route_date&page_size=100000`,
  );
  const list = data ? data.results?.map(item => new StudentBusGroup(item)) : [];
  return list;
}
async function getSearchedStudents(payload) {
  const params = {
    params: payload,
  };
  const { data } = await GraniteAPIClient(url).get(`/route-event-student-booking/search/`, params);
  return data ? data.results?.map(item => new StudentBusGroup(item)) : [];
}

async function sendEmergencyEmail(data) {
  try {
    let payload = mapToSnakeCaseForEmergency(data);
    await GraniteAPIClient(url).post('send-emergency-notification-email/', payload);
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function addBusAbsence(data) {
  try {
    let payload = mapToSnakeCaseForAbsence(data);
    let endpoint = '/absence/';
    if (payload?.parent?.pk) endpoint += 'parent/';
    await GraniteAPIClient(url).post(endpoint, payload);
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function addTraveller(data) {
  try {
    let payload = mapToSnakeCaseForAddTraveller(data);
    return await GraniteAPIClient(url).post('/bulk-create-route-event-student-booking-optimized/', payload);
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function changesAuditsLogs(params) {
  try {
    let payload = mapToSnakeCaseForChangeLogs(params);
    await GraniteAPIClient(url).post('/bus/audit/', payload);
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function changesParentAuditsLogs(params) {
  try {
    let payload = mapToSnakeCaseForChangeLogs(params);
    await GraniteAPIClient(url).post('/bus/audit/parent/', payload);
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function getRouteSmsBalance(organisationId = undefined) {
  const payload = { on_hold: 0, sms_balance: 0 };
  let { data } = await GraniteAPIClient(url).post(
    '/route-sms-balance/',
    payload,
    organisationId ? { headers: { Organisation: organisationId } } : {},
  );
  return data;
}

async function postMessageAllRoutes(params) {
  try {
    let payload = mapToSnakeCaseForMessageAllRoutes(params);
    const { data } = await GraniteAPIClient(url).post('/send-message-to-route/', payload);
    return data;
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function assignNewRouteToStudent(params, pk) {
  try {
    let payload = mapToSnakeCaseForAssignNewRouteToStudent(params);
    return await GraniteAPIClient(url).put(`/route-event-student-booking/${pk}/`, payload);
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function fetchStops(route_id) {
  let payload = { params: { route_id } };
  try {
    const { data } = await GraniteAPIClient(url).get('/route-stop-mapping/', payload);
    return data;
  } catch (e) {
    throw new GraniteError(e);
  }
}
async function deleteRoute(pk) {
  try {
    return await GraniteAPIClient(url).delete(`/route-event-student-booking/${pk}/`);
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function generateBusChangesPdf(date, setIsDisabled = () => {}, params) {
  const authToken = getAuthToken(true);
  const organisationId = getOrganisation();
  const tenantAccess = getTenantAccess();
  const headersData = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    organisation: organisationId,
    authorization: `${authToken}`,
  };

  let payload = {
    data: params,
  };
  const URL = `${logsLambda}?date=${date}`;
  fetch(URL, {
    method: 'post',
    headers: tenantAccess
      ? {
          ...headersData,
          'Tenant-access': tenantAccess,
        }
      : headersData,
    body: JSON.stringify(payload),
  })
    .then(res => res.arrayBuffer())
    .then(data => {
      handleDownload(data, 'Bus-Change-List.pdf', 'application/pdf');
      setIsDisabled(false);
    })
    .catch(e => {
      setIsDisabled(false);
      throw new GraniteError(e);
    });
}

async function downloadReports(payload, format, customURL) {
  try {
    const authToken = getAuthToken(true);
    const organisationId = getOrganisation();
    const URL = `${customURL ? customURL : downloadTransportReports}?export=${format}`;
    const tenantAccess = getTenantAccess();
    const headersData = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      organisation: organisationId,
      authorization: `${authToken}`,
    };
    const rep = await fetch(URL, {
      method: 'post',
      headers: tenantAccess
        ? {
            ...headersData,
            'Tenant-access': tenantAccess,
          }
        : headersData,
      body: JSON.stringify({
        data: {
          ...payload,
        },
      }),
    })
      .then(res => res.arrayBuffer())
      .then(res =>
        blobdata(res).then(res1 => {
          return res1 && res1.toString().includes('is_data') ? JSON.parse(res1) : res;
        }),
      )
      .then(data =>
        data.is_data === false ? data : handleDownload(data, `Reports.${format}`, `application/${format}`),
      );
    return rep;
  } catch (e) {
    throw new GraniteError(e);
  }
}

const handleDownload = (data, fileName, type) => {
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.style = 'display: none';
  const file = new Blob([data], { type });
  const url = window.URL.createObjectURL(file);
  link.href = url;
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

async function forceLogout(params) {
  await GraniteAPIClient(url).post('/force-logout/', params);
}

const blobdata = blob => new Response(blob).text();

async function fetchUpdatedManifest(payload, params) {
  try {
    const { data } = await GraniteAPIClient(manifestLambda).post('', { data: payload }, { params });
    const response = data?.map(stud => new updateManifestEntity(stud));
    return response;
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function stopsArrivalTime(params) {
  const routeIds = JSON.stringify(params);
  try {
    const { data } = await GraniteAPIClient(url).get(`list/route-stop-mapping/?route_ids=${routeIds}`);
    let listValue = [];
    for (let stop = 0; stop < data?.results?.length; stop++) {
      let item = data?.results?.[stop];
      listValue.push({ pk: item?.stop_id, routeId: item?.route_id, expectedArrivalTime: item?.expected_arrival_time });
    }
    return listValue;
  } catch (e) {
    throw new GraniteError(e);
  }
}
async function listStudentBookings(routeEventId) {
  try {
    const { data } = await GraniteAPIClient(url).get(`list/bookings/?route_event_id=${routeEventId}`);
    return data?.results;
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function fetchChargingMonitoring(routeEventId) {
  const { data } = await GraniteAPIClient(url).get(`/account-transactions/?route_event_id=${routeEventId}`);
  return data?.results;
}

export default {
  gettransportlist,
  getTransportData,
  getBusAbsenteeData,
  getBusChangeData,
  getStudentBusGroupData,
  sendEmergencyEmail,
  addBusAbsence,
  addTraveller,
  changesAuditsLogs,
  postMessageAllRoutes,
  getRouteSmsBalance,
  assignNewRouteToStudent,
  fetchStops,
  deleteRoute,
  getDriverData,
  generateBusChangesPdf,
  downloadReports,
  getSearchedStudents,
  forceLogout,
  changesParentAuditsLogs,
  fetchUpdatedManifest,
  fetchTransportStudentData,
  stopsArrivalTime,
  listStudentBookings,
  fetchChargingMonitoring,
};
