// Application Imports
import { getAuthToken, getOrganisation, getTenantAccess } from 'granite-admin/utils/auth-singleton';
import GraniteError from 'granite-admin/utils/granite-error';

/* eslint-disable */
const previewUrl = process.env.REACT_APP_ROLLOVER_URL;

async function postRollOverData(values) {
  try {
    const orgId = getOrganisation();
    const authToken = getAuthToken();
    const tenantAccess = getTenantAccess();
    const payload = { data: { ...values, organisation_id: orgId } };
    const headersData = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      organisation: orgId,
      authorization: `${authToken}`,
    };
    const response = await fetch(previewUrl, {
      method: 'post',
      headers: tenantAccess
        ? {
            ...headersData,
            'Tenant-access': tenantAccess,
          }
        : headersData,
      body: JSON.stringify(payload),
    });
    const data = await response.json();

    // If the response status is outside the success range (200-299), it will throw an error
    if (!response.ok) {
      throw new GraniteError(data);
    }
    return data;
  } catch (err) {
    throw new GraniteError(err);
  }
}

export default { postRollOverData };
