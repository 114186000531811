export const parseTopMenuItems = (data, userPreferenceData, selectedOrganisation = {}) => {
  data = data?.map(i => {
    if (i?.group?.toLowerCase() === 'rollcall settings') {
      let filterchildren;
      let child = i?.children[0];
      if (!userPreferenceData?.paymentModuleActive) {
        filterchildren = i?.children[0]?.children?.filter(j => j?.name !== 'pay-per-period');
      } else filterchildren = i?.children[0]?.children;
      return {
        ...i,
        children: [{ ...child, children: filterchildren?.sort((a, b) => a?.label?.localeCompare(b?.label)) }],
      };
    }
    if (i?.group?.toLowerCase() === 'dashboards') {
      let filterchildren;
      let child = i?.children[0];
      if (!userPreferenceData?.isDashletsEnabled) {
        filterchildren = i?.children[0]?.children?.filter(
          j => !['my-boards', 'dashboards', 'master-widgets'].includes(j?.name),
        );
      } else filterchildren = i?.children[0]?.children;
      return {
        ...i,
        children: [{ ...child, children: filterchildren }],
      };
    }
    return i;
  });
  let leftGroupItems = { group: 'left', children: [] };
  let rightGroupItems = { group: 'right', children: [] };
  data.forEach(i => {
    if (i.group.toLowerCase() !== 'right') {
      leftGroupItems.children = [...leftGroupItems?.children, ...i?.children]?.filter(child => {
        if (
          typeof userPreferenceData?.isSmartRouteEnabled === 'string' &&
          !JSON?.parse(userPreferenceData?.isSmartRouteEnabled)
        ) {
          if (child.name === 'route_creation') return false;
        }
        if (selectedOrganisation?.pk && !userPreferenceData?.isEnableGlobalDashboard) {
          if (child.name === 'global-dashboard') return false;
        }
        if (!userPreferenceData.isDashletsEnabled) {
          if (child.name === 'dashboard') return false;
        }
        return child;
      });
    } else rightGroupItems.children = [...rightGroupItems.children, ...i.children];
  });
  return [leftGroupItems, rightGroupItems];
};

export const parseRouteList = data => {
  let sortedList = {};
  for (let i = 0; i < data.length; i++) {
    let route = data[i];
    let groupName = route.groupName;
    if (sortedList[groupName]) {
      sortedList[groupName].push({
        pk: route.pk,
        route: route.eventName,
        eventId: route.pk,
        groupName: route.groupName,
        groupId: route.groupId,
        checkedItem: true,
      });
    } else {
      sortedList[groupName] = [
        {
          pk: route.pk,
          route: route.eventName,
          eventId: route.pk,
          groupName: route.groupName,
          groupId: route.groupId,
          checkedItem: true,
        },
      ];
    }
  }
  sortedList = Object.entries(sortedList).map(e => ({ [e[0]]: e[1] }));
  return sortedList;
};
