import React, { useMemo } from "react";
import { Box, Card, Heading } from 'grommet';

import Divider from 'granite-admin/core/components/Divider';
import EventEmitter from 'granite-admin/utils/event-emitter';

import LoginForm from "./components/LoginForm";

const ParentReset = () => {
	const eventEmitter = useMemo(() => new EventEmitter(), []);
	return (
		<Box>
			<Card pad='xxsmall' width='large' alignSelf='center' style={{ margin: '120px' }}>
				<Heading color='green' alignSelf='center' level={4}>Set password</Heading>
				<Divider color='black' />
				<LoginForm eventEmitter={eventEmitter} />
			</Card>
		</Box>
	);
};

export default ParentReset;
