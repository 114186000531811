// Lib Imports
import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

// CORE Imports
import FormField from 'granite-admin/core/components/FormField';
import PasswordInput from 'granite-admin/core/components/PasswordInput';

const ResetPasswordForm = ({ onSubmit, brandColor }) => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .max(50, 'Password is too Long')
      .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/, 'Atleast 6 char, One capital, One number is required')
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must be same.')
      .required('Password is required'),
  });

  return (
    <Formik
      initialValues={{
        password: '',
        confirm_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Text as="div" color="status-critical">
            {errors.title}
          </Text>
          <FormField name="password" label="Enter New Password" required error={touched.password && errors.password}>
            <PasswordInput
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type="password"
              iconColor={brandColor}
            />
          </FormField>
          <FormField
            name="confirm_password"
            label="Confirm New Password"
            required
            error={touched.confirm_password && errors.confirm_password}
          >
            <PasswordInput
              name="confirm_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm_password}
              type="password"
              iconColor={brandColor}
            />
          </FormField>
          <Box fill="horizontal" direction="column" elevation="none" justify="between" pad="none">
            <Button
              type="submit"
              alignSelf="start"
              margin={{ vertical: 'small' }}
              label={isSubmitting ? 'Submitting...' : 'Submit'}
              disabled={isSubmitting || !(isValid && dirty)}
              primary
              color={brandColor || 'secondary'}
            />
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  brandColor: PropTypes.string,
};

export default ResetPasswordForm;
