// lib imports
import React, { useState, useRef, useMemo } from 'react';
import { Button, Box, Drop, Text } from 'grommet';
import {
  Add,
  Edit,
  MoreVertical,
  Share,
  Calendar,
  Flag,
  Document,
  Trash,
  CloudUpload,
  View,
  Mail,
  Group,
  Currency,
  Location,
} from 'grommet-icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// CORE imports
import Popconfirm from 'granite-admin/core/components/Popconfirm';
import MenuButton from 'granite-admin/core/components/MenuButton';
import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import Loader from 'granite-admin/core/components/Loader';

const StyledBox = styled(Box)`
  &:focus {
    outline: none;
  }
`;
const renderItems = (showDrop, items, data) => {
  const itemList = items.map(item => {
    return (
      <MenuButton
        reverse
        key={item.name}
        label={item.label}
        icon={item.icon}
        onClick={() => {
          item.onClick(data);
          showDrop(false);
        }}
      />
    );
  });
  return <>{itemList}</>;
};

const ActionsCell = ({
  data: buttons,
  row,
  addHandler,
  actionIconColor,
  queryKey,
  newRows,
  columns,
  rowSaveLoader,
  ...rest
}) => {
  const actions = useMemo(() => {
    let list = [];
    let extra = {
      name: 'more',
      color: 'secondary',
      items: [],
    };

    if (buttons?.length) {
      buttons.forEach((button, index) => {
        if (index < 8) list.push(button);
        else extra.items.push(button);
      });
    }
    if (extra.items.length) list.push(extra);
    return list;
  }, [buttons]);

  const grandEmitter = useMemo(() => getGrandEmitter(), []);
  const [drop, showDrop] = useState(false);
  const childRef = useRef();
  let buttonsJsx = [];
  if (rowSaveLoader?.[row?.original?._type]) {
    buttonsJsx.push(
      <Box flex align="center" justify="center" direction="row">
        <Loader size="12px" />
        <Box pad={{ left: 'small' }}>Saving...</Box>
      </Box>,
    );
  }
  if (addHandler) {
    if (row?.original?._type) {
      if (!addHandler?.saveOn) {
        buttonsJsx.push(
          <Button
            title="save"
            icon={<CloudUpload size="small" color={actionIconColor} />}
            onClick={e => {
              e.stopPropagation();
              grandEmitter.emit(`${queryKey}_SAVE_NEW_ROW`, {
                index: row?.index,
                _type: row?.original?._type,
              });
            }}
            tabIndex={columns.length + row?.index}
          />,
        );
      }
      buttonsJsx.push(
        <StyledBox onClick={e => e.stopPropagation()} focusIndicator={false}>
          <Popconfirm
            align="right"
            onConfirm={() => {
              grandEmitter.emit(`${queryKey}_DELETE_ROW`, { index: row?.index, _type: row?.original?._type });
            }}
          >
            <Button
              icon={<Trash size="small" color={actionIconColor} />}
              onClick={() => {}}
              tabIndex={columns.length + row?.index}
            />
          </Popconfirm>
        </StyledBox>,
      );
    } else {
      if (!newRows[`add_${row?.original?.pk}`] && !row?.original?._type)
        buttonsJsx.push(
          <Button
            icon={<Add size="small" color={actionIconColor} />}
            onClick={e => {
              e.stopPropagation();
              grandEmitter.emit(`${queryKey}_ADD_NEW_ROW`, { index: row?.index, id: row?.original?.pk });
            }}
          />,
        );
    }
  }
  if (actions.length) {
    actions.forEach((btn, i) => {
      switch (btn.name) {
        case 'edit':
          buttonsJsx.push(<Button key={i} icon={<Edit size="small" color={btn.color} />} {...btn} />);
          break;
        case 'delete':
          buttonsJsx.push(
            <StyledBox key={i} onClick={e => e.stopPropagation()} focusIndicator={false}>
              <Popconfirm align="right" onConfirm={btn.onClick}>
                <Button icon={<Trash size="small" color={btn.color} />} {...btn} onClick={() => {}} />
              </Popconfirm>
            </StyledBox>,
          );
          break;
        case 'mail':
          buttonsJsx.push(
            <abbr title={'Mail'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={{ left: '0.8vw', top: '1vh' }}
                {...btn}
              >
                <Mail color={btn.color} size="small"></Mail>
              </Button>
            </abbr>,
          );
          break;
        case 'share':
          buttonsJsx.push(
            <abbr title={'Share'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={{ left: '0.8vw', top: '1vh' }}
                {...btn}
              >
                <Share color={btn.color} size="small"></Share>
              </Button>
            </abbr>,
          );
          break;
        case 'transation':
          buttonsJsx.push(
            <abbr title={'Transation'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={{ left: '0.8vw', top: '1vh' }}
                {...btn}
              >
                <Currency color={btn.color} size="small"></Currency>
              </Button>
            </abbr>,
          );
          break;
        case 'flag':
          buttonsJsx.push(
            <abbr title={'Flag'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={{ left: '0.8vw', top: '1vh' }}
                {...btn}
              >
                <Flag color={btn.color} size="small"></Flag>
              </Button>
            </abbr>,
          );
          break;
        case 'document':
          buttonsJsx.push(
            <abbr title={'Document'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={{ left: '0.8vw', top: '1vh' }}
                {...btn}
              >
                <Document color={btn.color} size="small"></Document>
              </Button>
            </abbr>,
          );
          break;
        case 'calender':
          buttonsJsx.push(
            <abbr title={'Calender'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={{ left: '0.8vw', top: '1vh' }}
                {...btn}
              >
                <Calendar color={btn.color} size="small" />
              </Button>
            </abbr>,
          );
          break;
        case 'group':
          buttonsJsx.push(
            <abbr title={'Group'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={{ left: '0.8vw', top: '1vh' }}
                {...btn}
              >
                <Group color={btn.color} size="small"></Group>
              </Button>
            </abbr>,
          );
          break;
        case 'location':
          buttonsJsx.push(
            <abbr title={'Location'}>
              <Button
                key={i}
                color={btn.color}
                onClick={e => {
                  e.stopPropagation();
                  btn.onclick(row.original);
                }}
                margin={btn.margin ? btn.margin : { left: '0.6vw', top: '1vh' }}
                position="fixed"
                {...btn}
              >
                <Location color={btn.color} size="small"></Location>
              </Button>
            </abbr>,
          );
          break;
        case 'view':
          buttonsJsx.push(<Button key={i} icon={<View size="small" color={btn.color} />} {...btn} />);
          break;
        case 'more':
          buttonsJsx.push(
            <Box onClick={e => e.stopPropagation()} key={i}>
              <Button
                key={i}
                icon={<MoreVertical size="small" color={btn.color} />}
                onClick={() => {
                  if (drop) {
                    showDrop(false);
                    childRef.current.blur();
                  } else showDrop(true);
                }}
                ref={childRef}
              />

              {childRef.current && drop && (
                <Drop
                  target={childRef.current}
                  elevation="small"
                  align={{ top: 'bottom', right: 'right' }}
                  onClickOutside={e => !childRef.current.contains(e.target) && showDrop(false)}
                >
                  {renderItems(showDrop, btn.items, row?.original)}
                </Drop>
              )}
            </Box>,
          );
          break;
        default:
          buttonsJsx.push(
            <Button
              key={i}
              {...btn}
              onClick={e => {
                e.stopPropagation();
                btn.onClick(row?.original);
              }}
            />,
          );
      }
    });
  }
  return (
    <>
      <Box direction="row" height={{ min: '26px' }}>
        {buttonsJsx}
      </Box>
      {row?.original?.errors && (
        <Text as="div" color="status-critical">
          {row?.original?.errors?.title}
        </Text>
      )}
    </>
  );
};

ActionsCell.propTypes = {
  data: PropTypes.array,
  row: PropTypes.object,
  addHandler: PropTypes.any,
  actionIconColor: PropTypes.string,
  queryKey: PropTypes.string,
  newRows: PropTypes.any,
  tabIndex: PropTypes.array,
  columns: PropTypes.array,
  rowSaveLoader: PropTypes.object,
};

export default ActionsCell;
