// Lib imports
import React, { useState } from 'react';
import { Box, Text, Layer } from 'grommet';
import PropTypes from 'prop-types';
import Divider from 'granite-admin/core/components/Divider';
import ButtonGroup from 'granite-admin/core/components/ButtonGroup';
import { setAuthToken, setTenantAccess, setOrganisation, resetAuthToken, resetSsoLogin} from 'granite-admin/utils/auth-singleton';
// import { removeAuthToken } from 'granite-admin/accounts/controllers/sidebar';

const groupOptions = [
  {
    label: 'Business Admin',
    value: 'BA',
  },
  {
    label: "Staff's Member",
    value: 'TENANT',
  },
];

const groupOptions2 = [
  {
    label: 'RollCall Admin',
    value: 'RA',
  },
  {
    label: "Staff's Member",
    value: 'SM',
  },
];

const optionProps = {
  width: '150px',
  height: { min: '55px' },
  elevation: 'small',
  align: 'center',
  justify: 'center',
};

const LoginAsLayer = ({ closeLayer, goToDashboard, loginAsLayer }) => {
  resetAuthToken(false);
  resetSsoLogin();
  const [value, setValue] = useState(null);

  const handleOnChange = value => {
    setValue(value);
    if (value === 'BA' || value === 'RA') {
      setTenantAccess(false);
    } else {
      setTenantAccess(true);
    }
    if (value === 'RA') setOrganisation(loginAsLayer.mappedOrgId);
    if (loginAsLayer.URL && value !== 'BA') {
      let URL = `${loginAsLayer.URL}&tenantAccess=true`;
      // localStorage.clear();
      window.location = URL;
    } else {
      setAuthToken(loginAsLayer.authToken);
      goToDashboard();
    }
  };

  return (
    <Layer position="center" background="full-screen-background-color" onClickOutside={closeLayer} onEsc={closeLayer}>
      <Box pad="small" flex={false}>
        <Text size="xlarge" weight="bold">
          Login As
        </Text>
      </Box>
      <Divider color="brand" />
      <Box flex={{ shrink: 0 }} pad="medium" width="100%" height={{ min: '60px' }} align="center">
        <ButtonGroup
          name="formName"
          options={loginAsLayer?.URL ? groupOptions : groupOptions2}
          value={value}
          onChange={handleOnChange}
          hoverBackground="brand"
          gap="small"
          optionProps={optionProps}
        />
      </Box>
    </Layer>
  );
};

LoginAsLayer.propTypes = {
  successToast: PropTypes.func,
  closeLayer: PropTypes.func,
  goToDashboard: PropTypes.func,
  loginAsLayer: PropTypes.object,
};

export default LoginAsLayer;
