export const fetchCoordinates = (scanArr = []) => {
  return scanArr?.map(scan => {
    return scan?.action_datetime
      ? ' [ ' +
          scan?.action_datetime?.slice(11) +
          ' ] ' +
          (typeof scan?.action_address === 'string'
            ? scan?.action_address?.charAt?.(0)?.toUpperCase() + scan?.action_address?.slice?.(1)?.toLowerCase()
            : 'UNKNOWN')
      : null;
  });
};

const fetchReasonOfCharge = data => {
  const chargeMointoringValue = data?.chargeMonitoringData?.find(
    i => i?.route_event_student_booking_id === data?.route_event_student_booking_id,
  );
  const fromZone = chargeMointoringValue?.extra_data?.from_zone_name;
  const toZone = chargeMointoringValue?.extra_data?.to_zone_name;

  return data?.route_status === 'COMPLETED'
    ? data?.exception_details && data?.exception_details.some(item => item.toLowerCase().includes('not travelled'))
      ? ''
      : `${
          data?.payment_option.toLowerCase().includes('period')
            ? 'PAY PER PERIOD - No Charge'
            : data?.payment_option.toLowerCase().includes('payg') && chargeMointoringValue
            ? data?.fixed_fare !== '0.00'
              ? `PAYG - Fixed Fare - $${data?.fixed_fare}`
              : `PAYG - ${
                  fromZone && toZone
                    ? `${fromZone} to ${toZone} ${
                        data?.discount_percentage !== '0.00' ? `- Discount ${data?.discount_percentage}%` : ''
                      }`
                    : `${chargeMointoringValue?.reason}`
                }`
            : 'INFORMATION NOT AVAILABLE'
        }`
    : '';
};

class TransportListEntity {
  constructor(data) {
    this.pk = data?.id || '';
    this.studentName = data?.student_name || '';
    this.status = data?.status || '';
    this.name = data?.stop || '';
    this.stopId = data?.stop_id;
    this.stopOrder = data?.stop_order || 0;
    this.routeColor = data?.route_color || '';
    this.driverLoginDatetime = data?.driver_login_datetime || '';
    //TODO: Need to confirm scanon dasta format
    this.scanOn = data?.extra_data?.scan_on
      ? fetchCoordinates(data?.extra_data?.scan_on)
      : data?.scan_on?.length
      ? fetchCoordinates(data?.scan_on)
      : '-';
    this.scanOff = data?.extra_data?.scan_off
      ? fetchCoordinates(data?.extra_data?.scan_off)
      : data?.scan_off?.length
      ? fetchCoordinates(data?.scan_off)
      : '-';
    this.exception =
      data.exception_details || data.behaviour_exception_detail || data.behaviour_exception
        ? [
            ...(data?.exception_details?.length ? data.exception_details : []),
            ...(data?.behaviour_exception_detail?.length ? data.behaviour_exception_detail : []),
          ]
        : '';
    this.noCardExceptions = data?.no_card_exceptions;
    this.scanAddress = [data?.scan_on?.at(-1), data?.scan_off?.at(-1)];
    // this.scanAddress = [...(data?.scan_on?.length ? data?.scan_on:[]),...(data?.scan_off?.length ? data?.scan_off : [])];
    // this.scanOffAddress = data?.extra_data?.scan_off;
    this.studentCode = data?.student_code || '';
    this.routeStatus = data?.route_status || '';
    this.routeEventStudentBookingId = data?.route_event_student_booking_id;
    this.isEditable = data?.is_editable;
    this.stopArrivalTime = data?.stopArrivalTimeRef?.current?.find(
      i => i.pk === data?.stop_id && i.routeId === data?.eventId,
    )?.expectedArrivalTime;
    this.amountCharged =
      data?.chargeMonitoringData?.find(i => i?.route_event_student_booking_id === data?.route_event_student_booking_id)
        ?.transaction_amount ?? '';
    this.reasonOfCharge = fetchReasonOfCharge(data);
  }
}

class BusAbsentee {
  constructor(data) {
    this.pk = data?.pk;
    this.studentName = data?.student?.first_name + ' ' + data?.student?.last_name;
    this.grade = data.student?.grade;
    this.registerName = data?.admin?.name || data?.parent?.name;
    this.parentEmail = data?.parent?.email;
    // this.adminName = data?.admin?.name;
    this.adminEmail = data?.admin?.email;
    this.absenceType = data?.absence_type;
    this.reason = data?.reason;
    this.createdAt = data?.created_at;
  }
}

class BusChange {
  constructor(data) {
    this.action = data?.action;
    this.grade = data?.grade;
    this.routeGroup = data?.route_group;
    this.parentEmail = data?.parent_email;
    this.adminEmail = data?.admin_email;
    this.studentName = data?.student_name;
    this.actor = data?.actor;
    this.oldData = data?.old_data;
    this.newData = data?.new_data;
    this.auditCreatedAt = data?.audit_created_at;
  }
}
class StudentBusGroup {
  constructor(data) {
    this.pk = data.pk;
    this.student = data?.student?.name || data?.student_name;
    this.routeDate = data?.route_event?.route_date || data?.route_date;
    this.routeStatus = data?.route_event?.route_status;
    this.eventName = data?.route_event?.route?.name || data?.route_name;
    this.eventPk = data?.route_event?.route?.pk;
    this.eventGroup = data?.route_event?.route?.route_group?.group_name || data?.group_name;
    this.routeEventId = data?.route_event_id;
    this.status = data?.status;
    this.eventGroupPk = data?.route_event?.route?.route_group?.pk;
    this.eventGroupCode = data?.route_event?.route?.route_group?.group_code;
    this.scanOnAction = data?.extra_data?.scan_on?.[0]?.action;
    this.scanOffAction = data?.extra_data?.scan_off?.[0]?.action;
  }
}
class updateManifestEntity {
  constructor({ status, scan_on, scan_off, exceptions, student, is_editable, no_card_exceptions }) {
    this.status = status;
    this.scanOn = scan_on?.length ? fetchCoordinates(scan_on) : '-';
    this.scanOff = scan_off?.length ? fetchCoordinates(scan_off) : '-';
    this.exception = exceptions;
    this.noCardExceptions = no_card_exceptions;
    this.student = student;
    this.scanAddress = [scan_on?.at(-1), scan_off?.at(-1)];
    this.isEditable = is_editable;
  }
}

export { TransportListEntity, BusAbsentee, BusChange, StudentBusGroup, updateManifestEntity };
