import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Text, DropButton, Button } from 'grommet';
import { FormDown } from 'grommet-icons';
import styled from 'styled-components';

const StyledSpan = styled.span`
  padding-top: 3px;
  svg {
    fill: ${({ isActive, theme }) =>
    isActive
      ? theme.global.colors['sidebar-svg-active'] + `!important`
      : theme.global.colors['sidebar-svg'] + `!important`};
  }
`;
const StyledBox = styled(Box)`
  width: 170px;
  padding: 10px 10px;
  background-color: #fff;
  white-space: nowrap;
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;
const StyledDropButton = styled(DropButton)`
  padding: 0.5rem;
  margin: 0;
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;

const MoreLeftItems = ({ subItems, theme }) => {
  return (
    <StyledDropButton
      plain
      alignSelf="center"
      theme={theme}
      margin={{ vertical: 'small' }}
      dropContent={renderItems(subItems)}
      dropProps={{ align: { top: 'bottom', left: 'left' } }}
    >
      <Button
        plain
        as="div"
        style={{ display: 'flex' }}
        reverse
        icon={<FormDown size="small" />}
        label={<Text>More </Text>}
      />
    </StyledDropButton>
  );
};

const itemBox = (i, index) => {
  return (
    <Link
      key={index}
      to={'/' + i.path}
      state={{ url: i.url }}
    >
      <StyledBox focusIndicator={false} direction="row" gap="small" align="end">
        {i.icon && typeof i.icon === 'string' ? (
          <StyledSpan style={{ display: 'flex' }} dangerouslySetInnerHTML={{ __html: i.icon }} />
        ) : (
          i.icon
        )}
        <Text>{i.label}</Text>
      </StyledBox>
    </Link>
  );
};

const renderSubItems = data => <>{data.map((i, index) => itemBox(i, index))}</>;

const renderItems = subItems => (
  <>
    {subItems.map((i, index) =>
      i.children ? (
        <DropButton
          focusIndicator={false}
          key={index}
          dropContent={renderSubItems(i.children)}
          dropProps={{ align: { top: 'bottom', left: 'right' } }}
        >
          <StyledBox direction="row" align="end" gap="small" focusIndicator={false}>
            {i.icon && typeof i.icon === 'string' ? (
              <StyledSpan style={{ display: 'flex' }} dangerouslySetInnerHTML={{ __html: i.icon }} />
            ) : (
              i.icon
            )}
            <Text>{i.label}</Text>
            <FormDown size="small" style={{ marginLeft: 'auto' }} />
          </StyledBox>
        </DropButton>
      ) : (
        itemBox(i, index)
      ),
    )}
  </>
);

MoreLeftItems.propTypes = {
  subItems: PropTypes.any,
  theme: PropTypes.any,
};

export default MoreLeftItems;
