// Lib Imports
import React, { useCallback, useEffect } from 'react';
import { Anchor, Box, Paragraph } from 'grommet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Loader from 'granite-admin/core/components/Loader';

const CheckUserStatus = ({ query, brandColor, setActiveStep, reqUserStatus, checkStatus, setLoader, loader }) => {
  const { token = '' } = query ?? {};

  const clickedToActiveStep = useCallback(() => setActiveStep(3), [setActiveStep]);

  useEffect(() => {
    if (token) {
      setLoader(true);
      reqUserStatus(token);
    }
  }, [setLoader, token]);

  return loader ? (
    <Box align="center" height="100%" justify="center">
      <Loader />
    </Box>
  ) : (
    <>
      <Paragraph size="16px" margin="0">
        {checkStatus?.isValid ? (
          <>
            Your account <b>{checkStatus?.user_email ? checkStatus.user_email : ''}</b> is already active. Would you
            like to login using your existing credentials or reset your password?
          </>
        ) : (
          <>Your link is invalid. Please contact your administrator to send it again.</>
        )}
      </Paragraph>
      {checkStatus?.isValid && (
        <Box
          fill="horizontal"
          direction="row-responsive"
          elevation="none"
          justify="between"
          align="center"
          pad="none"
          margin={{ top: 'small' }}
        >
          <Link
            to={
              window?.location?.pathname?.includes('/parent')
                ? `/parent/forgot-password?token=${token}`
                : `/forgot-password?token=${token}`
            }
            onClick={clickedToActiveStep}
          >
            <Anchor as="span" label="Reset Password" size="medium" color={brandColor || 'secondary'} />
          </Link>
          <Link to={window?.location?.pathname?.includes('/parent') ? '/parent/login' : '/login'}>
            <Anchor
              as="span"
              label="Go to Login"
              size="xlarge"
              margin={{ vertical: 'medium' }}
              color={brandColor || 'secondary'}
            />
          </Link>
        </Box>
      )}
    </>
  );
};

CheckUserStatus.propTypes = {
  query: PropTypes.any,
  brandColor: PropTypes.any,
  setActiveStep: PropTypes.func,
  reqUserStatus: PropTypes.func,
  checkStatus: PropTypes.object,
  setLoader: PropTypes.func,
  loader: PropTypes.string,
};

export default CheckUserStatus;
