// Core imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { setOrganisation } from 'granite-admin/utils/auth-singleton';
import { env } from 'granite-admin/env';

import User from 'accounts/entities/user';

const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ACCOUNTS_API;
const baseUrl = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
// const url = process.env.REACT_APP_ACCOUNTS_BASE_URL;
// const baseUrl = process.env.REACT_APP_ROLLCALL_BASE_URL;
// const accURL = process.env.REACT_APP_ORGANISATION_BASE_URL;

async function myProfile() {
  const { data } = await GraniteAPIClient(baseUrl).get('/user/profile/');
  const user = new User(data);
  return user;
}
async function resetParentPassword(data) {
  const isParent = window?.location?.pathname?.includes('/parent');
  const payload = data;
  await GraniteAPIClient(baseUrl).post(
    `${isParent ? '/parent/recover-password/' : '/user/recover-password/'}`,
    payload,
  );
}

async function isSSOBtnVisible(subdomain) {
  try {
    return await GraniteAPIClient(baseUrl).get(
      `/get-org-params/?domain=${subdomain}&keys=forgerock_login_text,is_sso_enabled`,
    );
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function login(email, password, organisation_id, currentSubdomain, screen) {
  let mainUrl = process.env.REACT_APP_MAIN_URL;
  let envName = mainUrl?.split('.')?.[0]?.replace('https://', '')?.replace('http://', '');
  const payload = {
    email,
    password,
    client: process.env.REACT_APP_CLIENT_ID,
    sub_domain: currentSubdomain !== envName ? `${currentSubdomain}.${envName}` : envName,
    screen: screen,
  };
  const headers = organisation_id ? { organisation: organisation_id } : null;

  const response = await GraniteAPIClient(baseUrl, headers).post(`/user/login/`, payload);
  if (organisation_id) setOrganisation(organisation_id);
  return response.data;
}

async function otpSubmit(values) {
  const payload = { email: values.email, otp: values.otp };
  try {
    const response = await GraniteAPIClient(url).post('user/login/otp/submit/', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function resetPassword(jwt_token, new_password, confirm_password) {
  const payload = {
    jwt_token,
    new_password,
    confirm_password,
  };
  await GraniteAPIClient(baseUrl).post('/user/new-password/', payload);
}

async function changeUserPreference(ID, value) {
  const payload = {
    value: value,
  };
  await GraniteAPIClient(url).put(`/preference/user-preference/${ID}/`, payload);
}

async function updateMyProfile(first_name, last_name, imageKey) {
  let userData = {};
  if (first_name) userData.first_name = first_name;
  if (last_name) userData.last_name = last_name;
  if (imageKey) userData.profile_image = imageKey;

  const { data } = await GraniteAPIClient(url).put('/user/me/', userData);
  const user = new User(data);
  return user;
}

async function requestToken(data) {
  const payload = data;
  const isParent = window?.location?.pathname?.includes('/parent');
  await GraniteAPIClient(baseUrl).post(
    `${isParent ? '/parent/recover-password/' : '/user/recover-password/'}`,
    payload,
  );
}

async function checkUserStatus(payload) {
  const { data } = await GraniteAPIClient(baseUrl).post('/check-user-status/', payload);
  return data;
}

async function checkDuplicatePhone(phone) {
  const payload = { phone: phone };
  const { data } = await GraniteAPIClient(baseUrl).post('check-duplicate-phone/', payload);
  return data;
}

async function resendOtp(values) {
  const payload = { email: values.email, phone: values.phone };
  try {
    const response = await GraniteAPIClient(baseUrl).post('user/resend-activation/', payload);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export default {
  myProfile,
  resetParentPassword,
  isSSOBtnVisible,
  login,
  otpSubmit,
  resetPassword,
  changeUserPreference,
  updateMyProfile,
  requestToken,
  checkUserStatus,
  checkDuplicatePhone,
  resendOtp,
};
