// Lib Imports
import React, { useEffect, useMemo, useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Heading, ThemeContext } from 'grommet';

// CORE Imports
import EventEmitter from 'granite-admin/utils/event-emitter';
import { useToast } from 'granite-admin/core/components/Toast';
import SplitLayout from 'granite-admin/core/components/SplitLayout';

// Application Imports

import { getAuthToken } from 'granite-admin/utils/auth-singleton';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import { otpSubmit } from 'accounts/controllers/user';
import OtpVerificationForm from './components/OtpVerificationForm';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';

import LoginAsLayer from 'pages/adminLogin/components/LoginLayer';

import { env } from 'granite-admin/env';

const type = env.REACT_APP_REGISTER_TYPE || 'email';

const OtpSubmit = ({ location, sideContent, customLogo, brandColor, formPadding, bannerPadding }) => {
  const { successToast, errorToast } = useToast();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const navigate = useNavigate();
  const config = useContext(ConfigContext);
  const { SideContent } = config?.sideContentSettings || '';
  const theme = useContext(ThemeContext);
  const { sideProps, sideWidth } = theme?.sideContentSettings || '';
  const [loginAsLayer, setLoginAsLayer] = useState(false);

  const goToDashboard = useCallback(
    () => (window.location.pathname === '/parent/otp-submit' ? navigate('/parent') : navigate('/dashboard')),
    [navigate],
  );

  useEffect(
    function init() {
      const observable = eventEmitter.getObservable();
      listenObservable();

      function listenObservable() {
        observable.subscribe(event => {
          switch (event.type) {
            // case 'OTP_SUCCESS':
            case 'LOGIN_SUCCESS':
              successToast('Verification successful');
              goToDashboard();
              break;
            case 'OTP_FAILURE':
              errorToast('Please enter the correct OTP');
              break;
            case 'ONLY_PARENT_LOGIN':
              errorToast(
                'The credentials being used do not belong to an admin, please try logging in to the Parent Portal below',
              );
              break;
            case 'ONLY_BA_ACCESSING_WRONG_ORG':
              errorToast('User not belongs to this organization');
              break;
            case 'BA_CAN_BE_EMPLOYEE': {
              const auth = getAuthToken();
              // if (event.data?.url)
              setLoginAsLayer({ authToken: auth, URL: event.data?.url, mappedOrgId: event.data?.orgId });
              // else {
              // resetAuthToken();
              // setTenantAccess(true);
              // setAuthToken(auth);
              // goToDashboard();
              // }
              break;
            }
            default:
          }
        });
      }
    },
    [eventEmitter, navigate, successToast, errorToast],
  );

  const closeLayer = () => {
    setLoginAsLayer(false);
    removeAuthToken(); //in case user doesn't select any option from the loginAsLayer, delete jwtToken
  };

  return (
    <>
      <SplitLayout
        sideContent={SideContent ? <SideContent /> : sideContent}
        mainContent={
          <Box width="large" flex="grow">
            <Heading level={3}>Two Factor Login Verification</Heading>
            <Heading level={5} size="large" color="dark-2">
              Enter the One Time Password (OTP) sent on your registered{' '}
              {type === 'email' ? 'email address' : 'phone number'}.
            </Heading>
            <OtpVerificationForm
              otpSubmit={otpSubmit}
              eventEmitter={eventEmitter}
              type={type}
              location={location}
              brandColor={brandColor}
            />
          </Box>
        }
        customLogo={customLogo}
        sideWidth={sideWidth}
        formPadding={formPadding}
        sideProps={sideProps}
        bannerPadding={bannerPadding}
      />
      {loginAsLayer && (
        <LoginAsLayer closeLayer={closeLayer} goToDashboard={goToDashboard} loginAsLayer={loginAsLayer} />
      )}
    </>
  );
};

OtpSubmit.propTypes = {
  location: PropTypes.object,
  sideContent: PropTypes.object,
  sideWidth: PropTypes.number,
  brandColor: PropTypes.string,
  customLogo: PropTypes.string,
  formPadding: PropTypes.any,
  bannerPadding: PropTypes.string,
};

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(OtpSubmit);
