import React from 'react';
import { Box, Text, Heading } from 'grommet';
import { Validate } from 'grommet-icons';

const SuccessScreen = () => {
  return (
    <Box fill={true} justify="center" align="center" pad="large">
      <Validate size="large" color="green" />
      <Heading textAlign="center" level={5} weight={600} style={{ marginTop: '25px' }}>
        Two-factor authentication enabled!
      </Heading>
      <Text textAlign="center" size="large" color="#8E8E8E" weight={600}>
        From now on when you sign in to your RollCall account, you'll need to enter both your password and a security
        code from your device.
      </Text>
    </Box>
  );
};

export default SuccessScreen;
