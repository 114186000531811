// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';

// Application Imports
import routeMessageAPI from '../gateways/routeMessage-api';
import { TOPNAVBAR_EVENTS } from './constants';

async function fetchRouteMessageLogs(eventEmitter, values) {
  if (_.isNil(values)) {
    throw new GraniteError('userData is invalid');
  }
  try {
    const messageLogs = await routeMessageAPI.getRouteMessageLogs(values);
    eventEmitter.emit(TOPNAVBAR_EVENTS.FETCH_LOGS_SUCCESS, messageLogs);
  } catch (error) {
    eventEmitter.emit(TOPNAVBAR_EVENTS.FETCH_LOGS_FAILURE);
    throw error;
  }
}

export { fetchRouteMessageLogs };