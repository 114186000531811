// Lib Imports
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Stack, Text, DropButton } from 'grommet';
import styled from 'styled-components';
import { Bell } from '@styled-icons/fa-solid/Bell';
import PropTypes from 'prop-types';
import _ from 'lodash';

// Core Imports
// import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import useWebSocket from 'common/useWebSocket';
import useNotificationsCount from 'granite-admin/utils/useNotificationsCount';

import { fetchNotifications } from 'common/TopNavbar/gateways/notifications-api';
import NotificationStack from 'common/TopNavbar/components/NotificationStack';
import useUtility from 'granite-admin/utils/useUtility';

const StyledMenu = styled(DropButton)`
  padding: 0 5px;
  svg {
    color: ${({ isActive, theme }) => theme.global.colors['sidebar-svg-hover'] + `!important`};
  }
  // &:hover .bell-count {
  //   background: ${({ isActive, theme }) => theme.global.colors['sidebar-svg-hover'] + `!important`};
  // }
  // &:hover .bell-count span {
  //   color: ${({ isActive, theme }) => theme.global.colors['sidebar-icon'] + `!important`};
  // }
  // &:hover span {
  //   color: ${({ isActive, theme }) => theme.global.colors['sidebar-label-hover'] + `!important`};
  // }
`;

const AnimationBox = styled(Box)`
  ${({ animatedIcon }) => {
    if (animatedIcon)
      return `
    animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

  @-webkit-keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }`;
  }}
`;

const Notification = ({ navigate, hoverIndicatorOptions }) => {
  // const config = useContext(ConfigContext);
  const {
    handleFetchNotificationCount,
    handleCountOnNotificationClick,
    handleMarkAllReadClick,
    notificationsCount,
  } = useNotificationsCount();
  const { websocketInstance: nativeWebsocketInstance } = useWebSocket();
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState();
  const [animatedIcon, setAnimatedIcon] = useState(false);
  const [isRead, setIsRead] = useState(null);

  // useEffect(() => {
  //   handleFetchNotificationCount();
  //   const intervalId = setInterval(handleFetchNotificationCount, 1000 * 60);
  //   return () => clearInterval(intervalId);
  // }, [handleFetchNotificationCount]);

  useEffect(() => {
    function messageListener(eventData) {
      if (Object.keys(eventData).length) {
        const data = JSON.parse(eventData.data);
        if (data.event === 'notification') {
          handleFetchNotificationCount(notificationsCount + 1);
          setAnimatedIcon(true);
        }
      }
    }
    if (nativeWebsocketInstance) {
      nativeWebsocketInstance.addEventListener('message', messageListener);
    }
    return () => nativeWebsocketInstance.removeEventListener('message', messageListener);
  }, [handleFetchNotificationCount, nativeWebsocketInstance, notificationsCount]);

  const grandListner = useCallback(
    event => {
      switch (event.type) {
        case 'FETCH_NOTIFICATION_COUNT':
          handleFetchNotificationCount();
          break;
        case 'ANIMATE_BELL_ICON':
          setAnimatedIcon(true);
          break;
        default:
          break;
      }
    },
    [handleFetchNotificationCount],
  );
  useUtility({ grandListner });

  const handleFetchNotifications = isReadParam => {
    setLoader(true);
    let mypage;
    if (isReadParam === 'FETCH_UNREAD_ONSCROLL') {
      mypage = page;
      isReadParam = false;
    } else if (isReadParam === 'FETCH_UNREAD_ONCE') {
      setPage(1);
      mypage = 1;
      setNotifications([]);
      isReadParam = false;
    } else if (isReadParam === 'FETCH_ALL_NOTIFICATION') {
      mypage = 1;
      isReadParam = null;
    } else {
      mypage = page;
      isReadParam = null;
    }
    if (mypage == null) mypage = 1;

    fetchNotifications(mypage, isReadParam)
      .then(response => {
        if (page === 1) setNotifications(response.list);
        else setNotifications(notifications => _.uniqBy([...notifications, ...response.list], 'pk'));
        setPage(response.nextPage);
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };

  const handleNotificationClick = notification => {
    if (!notification.status) {
      handleCountOnNotificationClick(notification.pk);
      setNotifications(prev => prev.map(i => ({ ...i, status: i.pk === notification.pk ? true : i.status })));
    }
    if (notification.url !== '') navigate(notification.url);
  };

  const markAllRead = () => {
    handleMarkAllReadClick();
    setNotifications(prev => prev.map(i => ({ ...i, status: true })));
    setOpen(false);
  };

  const showUnread = showUnread => {
    if (showUnread !== 'FETCH_ALL') {
      setIsRead('FETCH_UNREAD_ONCE');
      handleFetchNotifications('FETCH_UNREAD_ONCE');
    } else {
      setIsRead(null);
      handleFetchNotifications('FETCH_ALL_NOTIFICATION');
    }
    setOpen(false);
  };

  return (
    <>
      <StyledMenu
        dropProps={{
          style: {
            backgroundColor: '#fff',
            transition: 'all 0.5s ease 0s',
            borderRadius: '3px',
            boxShadow: 'rgb(0 0 0 / 10%) 0px 4px 17px 6px',
          },
        }}
        dropAlign={{ top: 'bottom', right: 'right' }}
        onClose={() => {
          setPage(1);
          setNotifications([]);
          setIsRead(null);
          setOpen(false);
        }}
        onOpen={handleFetchNotifications}
        dropContent={
          <NotificationStack
            notifications={notifications}
            loader={loader}
            handleNotificationClick={handleNotificationClick}
            setPage={setPage}
            page={page}
            handleFetchNotifications={handleFetchNotifications}
            hoverIndicatorOptions={hoverIndicatorOptions}
            markAllRead={markAllRead}
            showUnread={showUnread}
            isRead={isRead}
            setOpen={setOpen}
            open={open}
          />
        }
      >
        <AnimationBox animatedIcon={animatedIcon} onAnimationEnd={() => setAnimatedIcon(false)}>
          <Stack anchor="top-right" className="stack">
            <Bell size="20px" />
            {notificationsCount && (
              <Box
                className="bell-count"
                flex="grow"
                background="sidebar-icon"
                width="10px"
                height="10px"
                align="center"
                justify="center"
                round="full"
                margin={{ left: '2rem' }}
                border={{ size: '1px', color: 'brand' }}
                pad="0.4rem"
              >
                <Text size="9px" color="sidebar-label-hover" weight="bold">
                  {notificationsCount > 9 ? '9+' : notificationsCount}
                </Text>
              </Box>
            )}
          </Stack>
        </AnimationBox>
      </StyledMenu>
    </>
  );
};

Notification.propTypes = {
  navigate: PropTypes.object,
  hoverIndicatorOptions: PropTypes.object,
};

Notification.defaultProps = {
  hoverIndicatorOptions: { color: 'hover' },
};

export default Notification;
