import React from "react";
import {Box, TextInput, Heading, Button, Text} from 'grommet';
import FormField from 'granite-admin/core/components/FormField';
import { Formik } from "formik";
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import PasswordInput from 'granite-admin/core/components/PasswordInput';
import * as Yup from 'yup';

const LoginForm = (eventEmitter, loginClicked) => {
    const validationSchema = Yup.object().shape({
        password: Yup.string()
          .min(6, 'Password is too short - should be 6 chars minimum.')
          .max(50, 'Too Long!')
          .required('Password is required'),
        confirm_password: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must be same.')
          .required('Password is required'),
      });
    const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
        await loginClicked(eventEmitter, values);
    } catch (e) {
        if (e.errors) setErrors(e.errors);
    }
    setSubmitting(false);
    };
    return (
        <Formik
        initialValues={{
            password: '',
            confirm_password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
                <Box pad={{ horizontal:'large', vertical: 'medium'}}>
                    <form onSubmit={handleSubmit}>
                        <Text as="div" color="status-critical">
                        {errors.title}
                        </Text>
                        <div id="password_error_strength"className="alert fade in" style={{backgroundColor: '#f0af7e', color: 'black', fontSize:'small', padding:'0 5px'}}>
                            <p style={{marginBottom: '0'}}>Your password must have the following:</p>
                            <ul style={{paddingLeft:'18px'}}>
                                <li><strong>8</strong> Characters in length</li>
                                <li>A mixture of <strong>Upper</strong> and <strong>Lower</strong> Case</li>
                                <li>At least 1 <strong>Number</strong> </li>
                                <li>At least 1 <strong>Character</strong> such as ! # % @</li>
                            </ul>
                        </div>
                        <FormField
                            name="password"
                            label= 'New Password'
                            required
                            error={touched.password && errors.password}
                        >
                        <PasswordInput name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}/>
                        </FormField>
                        <FormField
                            name="confirm_password"
                            label= 'Confirm New Password'
                            required
                            error={touched.confirm_password && errors.confirm_password}
                        >
                        <PasswordInput name="confirm_password" onChange={handleChange} onBlur={handleBlur} value={values.confirm_password}/>
                        </FormField>
                        <Box>
                        <Button
                            type="submit"
                            style={{width:'50%', padding: '14px 0', fontSize: 'large'}}
                            alignSelf="center"
                            // size='xxlarge'
                            margin={{ top: 'medium' }}
                            label={isSubmitting ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING : 'Reset Password'}
                            disabled={isSubmitting}
                            primary
                            color="#59B2E0"
                        />
                        </Box>
                    </form>
                </Box>
                )
            }}
        </Formik>
    );
};
export default LoginForm;