// Lib Imports
import React from 'react';
import { Box, Heading, Image } from 'grommet';
import { useNavigate } from 'react-router-dom';
import { LinkPrevious } from 'grommet-icons';

// Application Imports
import Activated from 'assets/Activated.svg';

const RequestSuccess = () => {
  const navigate = useNavigate();

  return (
    <Box align="center">
      <Box margin={{ top: 'medium', bottom: 'xlarge' }}>
        <Image src={Activated} />
      </Box>
      <Heading level={3} margin="none">
        Password reset request success!
      </Heading>
      <Heading level={5} color="dark-2">
        Your password reset link has been sent successfully!
      </Heading>
      <Heading level={6}>
        <Box
          focusIndicator={false}
          onClick={() => navigate(window?.location?.pathname?.includes('/parent') ? '/parent/login' : '/login')}
          direction="row"
          gap="small"
          style={{ alignItems: 'center' }}
        >
          <LinkPrevious size="small" /> Back to Login
        </Box>
      </Heading>
    </Box>
  );
};

export default RequestSuccess;
