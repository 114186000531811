// Lib imports
import React, { useContext } from 'react';
import { Box, TextInput, Button, Text, ThemeContext, Grid } from 'grommet';
import { LinkPrevious } from 'grommet-icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

// Core imports
import FormField from 'granite-admin/core/components/FormField';
import PhoneInput from 'granite-admin/core/components/PhoneInput/v2';
import Select from 'granite-admin/core/components/Select';
import { useToast } from 'granite-admin/core/components/Toast';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import ONBOARDING_MESSAGES from 'granite-admin/messages/onBoarding';
import useRouteChange from 'granite-admin/utils/useRouteChange';
import { setCookie, deleteCookie } from 'granite-admin/utils/storage';
import { getAuthToken, resetTenantAccess } from 'granite-admin/utils/auth-singleton';
import organisationApi from 'granite-admin/organisations/gateways/organisation-api';
import { env } from 'granite-admin/env';

// Application imports
import useWebSocket from 'common/useWebSocket';
import { onConnectionOpen, onConnectionClose } from 'common/CommonFunction.js';

const LoginForm = ({ companyTypesList, userProfile, dispatch, selectedOrganisation }) => {
  const isSystemAdmin = userProfile.isSystemAdmin;
  const { errorToast, successToast } = useToast();
  const config = useContext(ConfigContext);
  const { websocketInstance: nativeWebsocketInstance } = useWebSocket({
    onConnectionClose,
    onConnectionOpen,
    // onGetMessage,
  });

  const handleBackClick = () => window.history.back();
  const { prefillEmail = true } = useContext(ThemeContext) ?? {};
  useRouteChange({});

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Company Name is required'),
    industry: Yup.string().nullable().required('Company Type is required'),
    domain: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/, 'Invalid domain name'),
    ...(isSystemAdmin && {
      firstName: Yup.string().trim().required('User First Name is require'),
      lastName: Yup.string().trim().required('User Last Name is require'),
      phone: Yup.object()
        .required('Phone Number is required')
        .test(value => {
          if (value?.error) {
            return new Yup.ValidationError('Invalid phone number', undefined, 'phone');
          } else {
            return true;
          }
        }),
      email: Yup.string()
        .trim()
        .min(2, 'Email too short')
        .max(50, 'Email too long')
        .email('Invalid email address')
        .required('User Email is required'),
    }),
  });

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      let data;
      if (values.isSystemAdmin) {
        data = await organisationApi.createOrganizationOnListing(values);
      } else {
        data = await organisationApi.createOrganisation(values);
      }
      dispatch({
        type: 'organisations/UPDATE_ORGANISATIONS',
        data: [data],
      });
      dispatch({
        type: 'organisations/UPDATE_SELECTED_ORGANISATION',
        data: data,
      });
      successToast(ONBOARDING_MESSAGES.ONBOARDING_SUCCESS);

      const host = window.location.host;
      const currentSubdomain = host.split('.')[0];
      if (
        config?.subdomainRedirectEnabled &&
        data?.domain &&
        data?.domain !== currentSubdomain &&
        isSystemAdmin &&
        !selectedOrganisation.pk
      ) {
        if (nativeWebsocketInstance) nativeWebsocketInstance.close();
        resetTenantAccess();
        const authToken = getAuthToken();
        deleteCookie('organisationData');
        deleteCookie('BAorigin');
        deleteCookie('redirectDomain');
        localStorage.clear();
        const domain = host.substring(
          0,
          host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
        );
        setCookie('organisationData', JSON.stringify(data), 1, domain);
        setCookie('BAorigin', window.origin, 1, domain);
        setCookie('redirectDomain', 'yes', 1, domain);
        window.location = `${window.location.protocol}//${data.domain}.${domain}${
          window.location.port ? `:${window.location.port}` : ''
        }/login?access_token=${authToken}&handlePta=yes`;
      } else if (
        config?.subdomainRedirectEnabled &&
        data?.domain &&
        data?.domain !== currentSubdomain &&
        isSystemAdmin &&
        selectedOrganisation.pk
      ) {
        const authToken = getAuthToken();
        deleteCookie('organisationData');
        deleteCookie('BAorigin');
        deleteCookie('redirectDomain');
        const domain = host.substring(
          host.indexOf('.') + 1,
          host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
        );

        setCookie('organisationData', JSON.stringify(data), 1, domain);
        setCookie('BAorigin', window.origin, 1, domain);
        setCookie('redirectDomain', 'yes', 1, domain);
        localStorage.clear();
        window.location = `${window.location.protocol}//${data.domain}.${domain}${
          window.location.port ? `:${window.location.port}` : ''
        }/login?access_token=${authToken}&redirectDomain=yes`;
      } else window.location.href = '/dashboard';
    } catch ({ errors }) {
      if (errors) {
        setErrors(errors);
        console.log(errors);
        let errorMessage = errors.api_error || errors.title || errors.field_error;
        if (errorMessage) {
          errorToast(errorMessage);
        }
      }
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        name: '',
        firstName: '',
        lastName: '',
        phone: { number: '', dialCode: '61', countryCode: 'au', phone: '' },
        email: prefillEmail ? userProfile.email : '',
        industry: '',
        domain: '',
        isSystemAdmin: isSystemAdmin,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        setFieldTouched,
        isValid,
        dirty,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            {isSystemAdmin && (
              <>
                <Box margin={{ top: '20px' }}>
                  <Text size="large" style={{ alignSelf: 'bottom' }}>
                    Enter User Details
                  </Text>
                </Box>
                <FormField name="name" label={ONBOARDING_MESSAGES.NAME_LABEL} error={touched.name && errors.name}>
                  <TextInput name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} />
                </FormField>
                <Grid columns={['1/2', '1/2']} gap="small">
                  <FormField
                    name="firstName"
                    basis="90%"
                    label="First Name*"
                    error={touched.firstName && errors.firstName}
                  >
                    <TextInput name="firstName" onChange={handleChange} onBlur={handleBlur} value={values.firstName} />
                  </FormField>
                  <FormField name="lastName" basis="90%" label="Last Name*" error={touched.lastName && errors.lastName}>
                    <TextInput name="lastName" onChange={handleChange} onBlur={handleBlur} value={values.lastName} />
                  </FormField>
                </Grid>
                <Grid columns={['1/2', '1/2']} gap="small">
                  <FormField basis="90%" name="phone" label="Phone Number*" error={touched.phone && errors.phone}>
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                      }}
                      onChange={phone => setFieldValue('phone', { ...phone })}
                      onBlur={handleBlur}
                      value={values.phone}
                      alwaysDefaultMask
                    />
                  </FormField>
                  <FormField basis="90%" name="email" label="Email Address*" error={touched.email && errors.email}>
                    <TextInput name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
                  </FormField>
                </Grid>
                {(!env.REACT_APP_COMPANY_TYPE || !process.env.REACT_APP_COMPANY_TYPE) && (
                  <Grid columns={['1/2', '1/2']} gap="small">
                    <FormField
                      basis="90%"
                      name="industry"
                      label={ONBOARDING_MESSAGES.COMPANY_TYPE_LABEL}
                      error={touched.industry && errors.industry}
                    >
                      <Select
                        options={companyTypesList}
                        name="industry"
                        value={values.industry}
                        onChange={({ value }) => {
                          setFieldValue('industry', value);
                          if (!touched.industry) setFieldTouched('industry', true, false);
                        }}
                        onBlur={e => !touched.industry && setFieldTouched('industry', true)}
                      />
                    </FormField>
                    <FormField
                      basis="90%"
                      name="domain"
                      label={ONBOARDING_MESSAGES.DOMAIN_LABEL}
                      error={touched.domain && errors.domain}
                    >
                      <TextInput
                        name="domain"
                        onChange={e => {
                          setFieldValue('domain', e?.target?.value.replace(/-+/g, '-'));
                          setFieldTouched('domain', true);
                        }}
                        onBlur={() => !touched.domain && setFieldTouched('domain', true)}
                        value={values.domain}
                      />
                    </FormField>
                  </Grid>
                )}
              </>
            )}
            <Box direction="row" align="center" justify="between" pad={{ vertical: 'small', right: 'medium' }}>
              <Button
                type="submit"
                alignSelf="start"
                margin={{ vertical: 'medium' }}
                label={
                  isSubmitting
                    ? ONBOARDING_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING
                    : ONBOARDING_MESSAGES.SUBMIT_BUTTON_LABEL
                }
                disabled={isSubmitting || !(isValid && dirty)}
                primary
                color="secondary"
              />
              <Box
                focusIndicator={false}
                onClick={handleBackClick}
                direction="row"
                gap="small"
                style={{ alignItems: 'center' }}
              >
                <LinkPrevious size="small" />
                <Text>Back</Text>
              </Box>
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
          </form>
        );
      }}
    </Formik>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  eventEmitter: PropTypes.object,
  selectedOrganisation: PropTypes.object,
  userProfile: PropTypes.object,
  companyTypesList: PropTypes.array,
  dispatch: PropTypes.func,
};

export default LoginForm;
