// Core Imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { env } from 'granite-admin/env';

import SettingsEntity from 'companySettings/entities/settings';

const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ORGANISATION_API;
// const url = process.env.REACT_APP_ORGANISATION_BASE_URL;

async function getMasterConfigs(type) {
  const payload = { params: { config_type: type, page_size: 100 } };
  try {
    const { data } = await GraniteAPIClient().get('/config-master/', payload);
    const list = data.results.map(e => new SettingsEntity(e));
    return list;
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function getSettings(params, orgId) {
  try {
    const payload = { params: { ...params, page_size: 100 } };
    const { data } = await GraniteAPIClient(url).get('/settings/', payload);
    return data.results.map(({ pk, value, setting_key }) => ({ pk, value, name: setting_key }));
  } catch (error) {
    throw new GraniteError(error);
  }
}

export default { getMasterConfigs, getSettings };
