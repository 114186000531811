import { getOrganisation } from 'granite-admin/utils/auth-singleton.js';
const orgId = getOrganisation();

export const rcsFormatNumber = s => {
  let n = parseFloat(s).toFixed(2);
  if (isNaN(n)) {
    return '$0.00';
  }
  if (n >= 0) {
    return '$' + n.toString();
  } else {
    return n.toString().replace('-', '-$');
  }
};

export const userPreference = {
  '%d-%m-%Y': 'dd-MM-yyyy',
  '%d/%m/%Y': 'dd/MM/yyyy',
  '%m-%d-%Y': 'MM-dd-yyyy',
  '%m/%d/%Y': 'MM/dd/yyyy',
  '%Y-%m-%d': 'yyyy-MM-dd',
  '%Y/%m/%d': 'yyyy/MM/dd',
  '%d-%b-%Y': 'dd-MMM-yyyy',
  '%Y-%b-%d': 'yyyy-MMM-dd',
  '%d/%b/%Y': 'dd/MMM/yyyy',
  '%Y/%b/%d': 'yyyy/MMM/dd',
  '%Y-%m-%d %H:%M:%S': 'yyyy-MM-dd',
};
const formatter = date => {
  let separator = date.includes('-') === true ? '-' : '/';
  let dateParts = date.split(separator);
  let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject;
};

export const checkFormat = (dateFormat, date) => {
  if (dateFormat === 'dd-MM-yyyy' || dateFormat === 'dd/MM/yyyy') return formatter(date);
  else return date;
};

const getBoundsInfo = ({ lat, lng }, geofenceSize) => {
  const google = window['google'];
  const centerPoint = new google.maps.LatLng(lat, lng);
  const bounds = new google.maps.Circle({
    center: centerPoint,
    radius: geofenceSize / 2,
  }).getBounds();
  const jsonBounds = bounds?.toJSON();
  const polygon = [
    { lat: jsonBounds?.north, lng: jsonBounds?.west },
    { lat: jsonBounds?.north, lng: jsonBounds?.east },
    { lat: jsonBounds?.south, lng: jsonBounds?.west },
    { lat: jsonBounds?.south, lng: jsonBounds?.east },
    { lat: jsonBounds?.north, lng: jsonBounds?.west },
  ];
  return polygon;
};

export const getGeofence = markers => {
  const geofences = [];
  markers.map(item => {
    const geofence = {};
    geofence.name = item.name;
    geofence.lat = item.latitude;
    geofence.lng = item.longitude;
    geofence.polygon = getBoundsInfo({ lat: item.latitude, lng: item.longitude }, item?.geofenceSize ?? 0);
    geofence.radius = 0;
    geofence.typeId = 'RECTANGLE';
    geofences.push(geofence);
  });
  return geofences;
};
export const socketHeaders = {
  organisation: orgId,
};

const adminRoom = `web_admin`;
const parentRoom = `web_parent`;

export const ROOM_NAMES = { WEB_ADMIN: adminRoom, WEB_PARENT: parentRoom };

export const fetchCurrGrp = userPreferenceData => {
  let e = new Date().toLocaleString('en-US', { timeZone: userPreferenceData?.userTimezone || 'Australia/Melbourne' });
  if (e?.includes('AM')) return 'AM';
  else return 'PM';
};

export const addressDummyArr = [1, 2, 3, 4, 5, 6];

const schoolCountry = {
  australia: {
    countryCode: 'au',
    latlngZoom: {
      lat: -25.274398,
      lng: 133.775136,
      zoom: 4,
    },
  },
  'new zealand': {
    countryCode: 'nz',
    latlngZoom: {
      lat: -40.850109,
      lng: 174.767777,
      zoom: 5,
    },
  },
  singapore: {
    countryCode: 'sgp',
    latlngZoom: {
      lat: 1.3521,
      lng: 103.8198,
      zoom: 5,
    },
  },
  'united states': {
    countryCode: 'us',
    latlngZoom: {
      lat: 39.8097343,
      lng: -98.5556199,
      zoom: 5,
    },
  },
  canada: {
    countryCode: 'ca',
    latlngZoom: {
      lat: 60.04041,
      lng: -110.26897,
      zoom: 4,
    },
  },
  uae: {
    countryCode: 'ae',
    latlngZoom: {
      lat: 25.2048,
      lng: 55.2708,
      zoom: 10,
    },
  },
};

export const defaultGeoMap = userPreferenceData => {
  if (userPreferenceData?.country) {
    return schoolCountry[userPreferenceData?.country]?.latlngZoom;
  } else return { lat: -25.274398, lng: 133.775136, zoom: 4 };
};

export const possibleSchoolCountry = userPreferenceData => {
  return {
    componentRestrictions: {
      country: userPreferenceData?.country
        ? [schoolCountry[userPreferenceData?.country]?.countryCode]
        : ['au', 'nz', 'sgp'],
    },
  };
};

const phoneCountry = {
  australia: {
    dialCode: '61',
    number: '61',
    phone: '',
    countryCode: 'au',
  },
  'new zealand': {
    dialCode: '64',
    number: '64',
    phone: '',
    countryCode: 'nz',
  },
  singapore: {
    dialCode: '65',
    number: '65',
    phone: '',
    countryCode: 'sgp',
  },
  'united states': {
    dialCode: '1',
    number: '1',
    phone: '',
    countryCode: 'us',
  },
  canada: {
    dialCode: '1',
    number: '1',
    phone: '',
    countryCode: 'ca',
  },
  uae: {
    dialCode: '971',
    number: '971',
    phone: '',
    countryCode: 'ae',
  },
};
export const possiblePhoneCountry = userProfile => {
  return userProfile?.country ? phoneCountry[userProfile.country] : phoneCountry['australia'];
};

// School Admin is equivalent to 'AdminDriverParent', 'AdminParent','AdminDriver'
export const ROLES_LIST = ['AdminDriverParent', 'AdminParent', 'AdminDriver', 'School Admin'];

export const mainDomain = process.env.REACT_APP_MAIN_URL.replace('https://', '')?.replace('http://', '');

export function deleteAllCookies(domain, preserveCookieArr = []) {
  console.log('deleting..', mainDomain, domain);
  var allCookies = document.cookie.split(';');
  for (let i = 0; i < allCookies.length; i++) {
    let cookie = allCookies[i].trim();
    let cookieName = cookie.split('=')[0];
    if (!preserveCookieArr.includes(cookieName)) {
      document.cookie = cookie + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;${domain ? `domain=${domain}` : ''};path=/`;
    }
  }
}

export const defaultZonePinColor = '#808080';

export const fetchSelectedGroup = (list, userPreferenceData) => {
  let filteredByGroupCode = list?.filter(i => i?.groupCode === fetchCurrGrp(userPreferenceData));
  let selectedGrp =
    filteredByGroupCode?.find(i => i?.groupName === fetchCurrGrp(userPreferenceData)) || filteredByGroupCode[0];
  return selectedGrp ? selectedGrp : list[0];
};
