import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeAuthToken } from 'granite-admin/core/controllers/dashboardHeader';

import { resetTenantAccess } from 'granite-admin/utils/auth-singleton';
import { deleteAllCookies, mainDomain } from 'common/utils';
import useWebSocket from 'common/useWebSocket';

const Logout = () => {
  const { websocketInstance, closeConnection } = useWebSocket({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    deleteAllCookies(mainDomain);
    removeAuthToken();
    resetTenantAccess();
    dispatch({ type: 'accounts/user/RESET_STATE' });
    if (closeConnection && websocketInstance.readyState === WebSocket.OPEN) {
      closeConnection(true);
      // websocketInstance.close()
    }
    navigate('/login');
  }, [dispatch, navigate, websocketInstance, closeConnection]);

  return null;
};

export default Logout;
