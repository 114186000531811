// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';

// Application Imports
import { RouteMessageLogsEntity } from '../entities/routeMessage';
import GraniteError from 'granite-admin/utils/granite-error';
import { env } from 'granite-admin/env';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
const baseUrl = env.REACT_APP_API_BASE_URL + env.REACT_APP_ORGANISATION_API;
// const url = process.env.REACT_APP_ROLLCALL_BASE_URL;
// const baseUrl = process.env.REACT_APP_ORGANISATION_BASE_URL;

async function getRouteMessageLogs(params) {
  const payload = { params: { ...params, page_size: 100000 } };
  let res = [];
  const { data } = await GraniteAPIClient(url).get(`/messaging-logs/`, payload);
  data
    ? data.results?.map(item => {
        let { data } = new RouteMessageLogsEntity(item);
        res = [...res, ...data];
      })
    : [];
  return res;
}

// async function isLogoEnabled() {
//   const { data } = await GraniteAPIClient(baseUrl).get('/settings/?setting_key=is_logo_enabled');
//   return data?.results[0]?.value;
// }

async function getOrgSettingAllKey(payload) {
  const { data } = await GraniteAPIClient(baseUrl).get(`/settings/`, {
    params: payload,
  });
  return data?.results;
}

async function sendCustomEmail({ email, message }) {
  try {
    const payload = {
      email: email,
      message: message,
    };
    return await GraniteAPIClient(url).post(`/send-custom-email/`, payload);
  } catch (e) {
    throw new GraniteError(e);
  }
}

export default { getRouteMessageLogs, sendCustomEmail, getOrgSettingAllKey };
