// Lib Imports
import React, { useEffect, useContext, useCallback } from 'react';
import { Avatar, Button, Box, Text, DropButton, ThemeContext, Tag } from 'grommet';
import { CheckboxSelected, MoreVertical, Hide, View } from 'grommet-icons';
import styled from 'styled-components';
import { Bell } from '@styled-icons/fa-solid/Bell';
import PropTypes from 'prop-types';

// Core Imports
import Loader from 'granite-admin/core/components/Loader';
import NoRecordsPlaceholder from 'granite-admin/core/components/NoRecordsPlaceholder';

import NotificationSkeletonLoader from 'granite-admin/core/components/Notification/components/NotificationSkeletonLoader';

const StyledText = styled(Text)`
  a {
    color: #0e84ad;
    text-decoration: underline;
  }
  a:hover {
    color: #fbab31;
  }
`;

const AvatarBox = styled(Avatar)`
  position: relative;
`;
const AvatarBell = styled(Bell)`
  color: ${({ theme, background }) => theme.global.colors[background]};
`;
const StatusIcon = styled(Box)`
  position: absolute;
  top: 4px;
  right: 12px;
`;

const NotificationStack = ({
  notifications,
  loader,
  handleNotificationClick,
  page,
  handleFetchNotifications,
  hoverIndicatorOptions,
  markAllRead,
  showUnread,
  isRead,
  open,
  setOpen,
  showSkeletonLoader,
}) => {
  const theme = useContext(ThemeContext);

  const { headingColor = 'white', moreVerticalColor = 'white' } = theme?.notification || {};

  useEffect(() => {
    document.getElementsByClassName('notification_box')[0].addEventListener('click', () => {
      setOpen(false);
    });
  }, [setOpen]);

  const handleScroll = e => {
    const before = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    if (before && page && !loader) {
      if (isRead === 'FETCH_UNREAD_ONCE') handleFetchNotifications('FETCH_UNREAD_ONSCROLL');
      else handleFetchNotifications();
    }
  };

  const itemsRenderer = () => {
    return (
      <Box pad="small" gap="medium">
        <Button
          plain
          onClick={markAllRead}
          icon={<CheckboxSelected size="small" color="#2b2828" />}
          label={<Text size="14px"> Mark All Read</Text>}
          gap="10px"
        />

        <Button
          plain
          onClick={isRead !== 'FETCH_UNREAD_ONCE' ? showUnread : () => showUnread('FETCH_ALL')}
          icon={
            isRead !== 'FETCH_UNREAD_ONCE' ? <Hide size="23px" color="#615f5f" /> : <View size="17px" color="#615f5f" />
          }
          label={<Text size="14px">{isRead !== 'FETCH_UNREAD_ONCE' ? 'Show Unread' : 'Show All'}</Text>}
          alignSelf="start"
        />
      </Box>
    );
  };
  const disableAnchorTag = useCallback(htmlContent => {
    return htmlContent.replace(
      /<a\b[^>]*>/g,
      '<a style=" cursor: not-allowed!important; color:grey!important;" href="#">',
    );
  }, []);

  return (
    <Box background="sidebar-drops" width="328px" className="notification_box">
      <Box
        flex={{ shrink: 0 }}
        direction="row"
        background="brand"
        pad="12px"
        align="center"
        justify="between"
        width="100%"
      >
        <Text size="large" weight="bold" color={headingColor}>
          Notifications
        </Text>
        <DropButton
          icon={<MoreVertical size="small" color={moreVerticalColor} onClick={() => setOpen(!open)} />}
          dropAlign={{ top: 'bottom', right: 'right' }}
          dropProps={{
            round: 'small',
            elevation: 'medium',
            background: 'white',
            pad: 'small',
          }}
          dropContent={itemsRenderer()}
          open={open}
        />
      </Box>
      <Box height="352px" overflow={{ vertical: 'auto' }} onScroll={handleScroll}>
        {notifications?.length > 0
          ? notifications.map((notification, index) => (
              <Box
                key={index}
                align="center"
                onClick={() => handleNotificationClick(notification)}
                pad="small"
                hoverIndicator={hoverIndicatorOptions}
                flex={{ shrink: 0 }}
                focusIndicator={false}
              >
                <Box direction="row" gap="small" align="center">
                  {getUserAvatar(notification?.status)}
                  <Box width="medium" direction="row" align="start">
                    <StyledText weight="bold" dangerouslySetInnerHTML={{ __html: notification?.title }} />
                    <StyledText
                      dangerouslySetInnerHTML={{
                        __html: notification?.isExpired
                          ? disableAnchorTag(notification?.message || notification?.text)
                          : notification?.message || notification?.text,
                      }}
                    />
                    {notification?.isExpired && <Tag name="Expired" size="xsmall" style={{ fontWeight: 'bold' }} />}
                  </Box>
                </Box>
                <Text style={{ alignSelf: 'end' }} size="9px">
                  {notification?.createdAt}
                </Text>
              </Box>
            ))
          : !loader && (
              <Box elevation="small" round="small" fill pad="small">
                <NoRecordsPlaceholder msg="No Notification Found" />
              </Box>
            )}

        {loader && showSkeletonLoader ? (
          <NotificationSkeletonLoader />
        ) : (
          loader && (
            <Box flex={{ shrink: 0 }} width="100%" margin={{ vertical: 'medium' }} align="center" justify="center">
              <Loader size="20px" />
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

const getUserAvatar = status => {
  return (
    <AvatarBox>
      <Avatar size="34px" background="notification-avatar-background" round="full">
        <AvatarBell size="18px" background="notification-avatar-color" />
      </Avatar>
      {!status && (
        <StatusIcon
          flex={{ shrink: 0 }}
          round="full"
          background="notification-avatar-color"
          height="10px"
          width="10px"
        />
      )}
    </AvatarBox>
  );
};

NotificationStack.propTypes = {
  notifications: PropTypes.array,
  loader: PropTypes.bool,
  handleNotificationClick: PropTypes.func,
  page: PropTypes.number,
  handleFetchNotifications: PropTypes.func,
  markAllRead: PropTypes.func,
  hoverIndicatorOptions: PropTypes.object,
  isRead: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showUnread: PropTypes.func,
  showSkeletonLoader: PropTypes.bool,
};

export default NotificationStack;
