// Lib Imports
import React from 'react';
import { Anchor, Box, TextInput, Button, Text } from 'grommet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CORE Imports
import FormField from 'granite-admin/core/components/FormField';
import PhoneInput from 'granite-admin/core/components/PhoneInput/v2';
import Select from 'granite-admin/core/components/Select';

// const primaryContact = process.env.REACT_APP_PRIMARY_CONTACT || 'EMAIL';
// const allowAltRecovery = String(process.env.REACT_APP_ALLOW_ALTERNATE_RECOVERY) || 'false';

const primaryContact = 'EMAIL';
const allowAltRecovery = 'false';

const RequestTokenForm = ({ onSubmit, query, organisation, setOrganisation, brandColor }) => {
  const validationSchema = Yup.object().shape(
    {
      ...(organisation?.length && { organisation_id: Yup.number().required('Organization is required') }),
      phone: Yup.object().when('email', {
        is: email => !email || email.length === 0,
        then: Yup.object().test((value) => {
          if (value.error) {
            return new Yup.ValidationError('Invalid phone number', undefined, 'phone');
          } else {
            return true;
          }
        }),
      }),
      email: Yup.string().when('phone', {
        is: phone => !phone?.number || phone?.number.length === 0,
        then: Yup.string()
          .required('Email is required')
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .email('Invalid email'),
      }),
    },
    [['email', 'phone']],
  );

  const { email = '' } = query ?? {};

  return (
    <Formik
      initialValues={{
        email,
        organisation_id: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, isValid }) => (
        <form onSubmit={handleSubmit}>
          {/* <Text as="div" color="status-critical">
            {errors.title}
          </Text> */}
          {primaryContact === 'EMAIL' && allowAltRecovery === 'false' ? (
            <FormField name="email" label="Email" required error={touched.email && errors.email}>
              <TextInput
                type="email"
                name="email"
                placeholder="john.doe@gmail.com"
                onChange={e => {
                  handleChange(e);
                  setOrganisation([]);
                  setFieldValue('organisation_id', '');
                }}
                onBlur={handleBlur}
                value={values.email}
              />
            </FormField>
          ) : (primaryContact === 'EMAIL' || primaryContact === 'PHONE') && allowAltRecovery === 'true' ? (
            <Box>
              <FormField name="email" label="Email address" required error={touched.email && errors.email}>
                <TextInput
                  type="email"
                  name="email"
                  placeholder="john.doe@gmail.com"
                  onChange={e => {
                    handleChange(e);
                    setOrganisation([]);
                    setFieldValue('organisation_id', '');
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </FormField>
              <Box flex justify="end" align="center">
                <Text size="medium">Or</Text>
              </Box>
              <FormField
                name="phone"
                label={'Phone number'}
                error={touched.phone && errors.phone}
              >
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                  }}
                  onChange={phone => {
                    setFieldValue('phone', { ...phone });
                    setOrganisation([]);
                    setFieldValue('organisation_id', '');
                  }}
                  onBlur={handleBlur}
                  value={values.phone}
                  alwaysDefaultMask
                />
              </FormField>
            </Box>
          ) : primaryContact === 'PHONE' && allowAltRecovery === 'false' ? (
            <FormField name="phone" label={'Phone number'} error={touched.phone && errors.phone && errors.phone.number}>
              <PhoneInput
                inputProps={{
                  name: 'phone',
                }}
                onChange={phone => {
                  setFieldValue('phone', { ...phone });
                  setOrganisation([]);
                  setFieldValue('organisation_id', '');
                }}
                onBlur={handleBlur}
                value={values.phone}
                alwaysDefaultMask
              />
            </FormField>
          ) : null}
          {organisation?.length > 0 && (
            <FormField
              name="organisation_id"
              label="Select an Organization"
              error={touched.organisation_id && errors.organisation_id}
            >
              <Select
                name="organisation_id"
                options={organisation}
                labelKey="name"
                value={values.organisation_id}
                valueKey={{ key: 'pk', reduce: true }}
                onChange={({ value }) => {
                  setFieldValue('organisation_id', value);
                }}
              />
            </FormField>
          )}
          <Box
            fill="horizontal"
            direction="row-responsive"
            elevation="none"
            justify="between"
            align="center"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{ top: 'small' }}
              label={isSubmitting ? 'Sending Link...' : 'Send'}
              disabled={isSubmitting || !isValid}
              primary
              color={brandColor || 'secondary'}
            />
            <Link to={window?.location?.pathname?.includes("/parent") ? "/parent/login" : "/login"} >
              <Anchor
                as="span"
                label="Log in to existing account"
                size="small"
                margin={{ vertical: 'small' }}
                color={brandColor || 'secondary'}
              />
            </Link>
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  );
};

RequestTokenForm.propTypes = {
  onSubmit: PropTypes.func,
  query: PropTypes.any,
  organisation: PropTypes.object,
  setOrganisation: PropTypes.any,
  brandColor: PropTypes.any,
};

export default RequestTokenForm;
