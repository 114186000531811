// Lib imports
import _ from 'lodash/fp';

// Core imports
import GraniteError from 'granite-admin/utils/granite-error';

// Application Imports
import eventGroupAPI from 'eventGroups/gateways/event-api';
import { EVENTGROUPS_EDIT_EVENTS } from './constants';

async function fetchEventGroup(values) {
  try {
    return await eventGroupAPI.getEventGroup(values);
  } catch (error) {
    console.log(error);
  }
}

async function fetchEventGroupEmitter(values) {
  const { eventEmitter, ...rest } = values;
  // try {
  const eventGroupList = await eventGroupAPI.getEventGroup({ ...rest });
  eventEmitter.emit(EVENTGROUPS_EDIT_EVENTS.FETCH_EVENT_GROUP, eventGroupList);
  return eventGroupList;
  // } catch (error) {
  //   eventEmitter.emit(EVENTGROUPS_EDIT_EVENTS.FETCH_FAILURE);
  //   throw error?.errors?.title;
  // }
}

async function updateEventGroupClicked(eventEmitter, values) {
  if (_.isNil(values)) {
    throw new GraniteError('User data is invalid');
  }
  // try {
  const { data } = await eventGroupAPI.updateEventGroup(values);
  eventEmitter.emit(EVENTGROUPS_EDIT_EVENTS.SAVE_SUCCESS, data);
  // } catch (error) {
  //   eventEmitter.emit(EVENTGROUPS_EDIT_EVENTS.SAVE_FAILURE);
  //   throw error;
  // }
}

export { updateEventGroupClicked, fetchEventGroup, fetchEventGroupEmitter };
