// Lib Imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

// CORE Imports
import useEmitter from 'granite-admin/utils/useEmitter';
import Loader from 'granite-admin/core/components/Loader';
import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import usePreferences from 'granite-admin/utils/usePreferences';
import { getDefaultDashboard } from 'granite-admin/aggregate/controllers/dashboard';

import { fetchEventGroupEmitter } from 'eventGroups/controllers/eventGroup';
import { getFormatedDate } from 'common/CommonFunction.js';
import { fetchCurrGrp } from 'common/utils';
function MyBoard({ navigate, userOrganisations, userProfile }) {
  const [isGroupFetch, setIsGroupFetch] = useState(false);
  const [routeGroupValues, setRouteGroupValues] = useState({});
  const { fetchLoading, preferences: { config: { default_dashboard = {} } = {} } = {} } = usePreferences({
    route: '/dashboard',
    autoSavePreference: true,
  });

  const currentDate = getFormatedDate(new Date(), userProfile?.userTimezone);
  const currentGroup = fetchCurrGrp(userProfile) || 'AM';

  const date = { name: 'flatpicker_date', type: 'date', label: 'Date', customFormat: 'yyyy-MM-dd', value: currentDate };

  const eventEmitter = useEmitter(event => {
    switch (event.type) {
      case 'DEFAULT_DASHBOARD':
        navigate(
          `/my-boards/${event.data.pk}/${event.data.name}?Select Group=${encodeURIComponent(
            JSON.stringify(routeGroupValues),
          )}&Date=${encodeURIComponent(JSON.stringify(date))}`,
          {
            replace: true,
          },
        );
        break;
      case 'FETCH_EVENT_GROUP':
        setRouteGroupValues({
          name: 'select',
          type: 'select',
          label: 'Select Group',
          options: event?.data?.list?.map(i => i.groupName),
          value: currentGroup,
        });
        setIsGroupFetch(true);
      default:
        break;
    }
  });

  useEffect(() => {
    fetchEventGroupEmitter({ eventEmitter, ordering: 'group_name', page_size: 10000 });
  }, [eventEmitter]);

  // check if there are any userOrganisations if there is none, redirect to get-started page
  if (!userProfile.isSystemAdmin && userOrganisations.length === 0) {
    navigate('/get-started', { replace: true });
  }

  // redirect to buissness Admin dashboard
  // if (userProfile.isSystemAdmin && !selectedOrganisation?.pk) {
  //   navigate('/business-board/',{replace:true});
  // } else {
  //   getDefaultDashboard(eventEmitter);
  // }

  if (isGroupFetch && !fetchLoading) {
    if (Object.keys(default_dashboard).length === 2) {
      // navigate(`/my-boards/${default_dashboard.pk}/${default_dashboard.name}`, { replace: true });
      // getDefaultDashboard function handles the loading of either the default dashboard/0th index board, if present, or it loads the "No Dashboard Found" page otherwise.
      getDefaultDashboard(eventEmitter, default_dashboard.pk);
    } else {
      getDefaultDashboard(eventEmitter);
    }
  }

  return (
    <Box fill align="center" justify="center">
      <Loader />
    </Box>
  );
}

MyBoard.propTypes = {
  navigate: PropTypes.any,
  userOrganisations: PropTypes.array,
  selectedOrganisation: PropTypes.object,
  userProfile: PropTypes.object,
};
const mapStateToProps = state => ({
  userOrganisations: OrganisationDucks.organisations(state),
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  userProfile: UserDucks.profile(state),
});
export default connect(mapStateToProps)(MyBoard);
