import React from 'react';
import { Box, Layer, TableRow, Text } from 'grommet';
import PropTypes from 'prop-types';
import { StyledTable, StyledTableHeader, StyledTableBody, StyledTableCell } from '../controllers/constants';
import { Close } from 'grommet-icons';

import Divider from 'granite-admin/core/components/Divider';

const RecipientTable = ({ logs = [], closeRecipientLayer }) => {
  return (
    <Layer onClickOutside={closeRecipientLayer} onEsc={closeRecipientLayer}>
      <Box
        justify="between"
        direction="row"
        pad={{
          top: 'medium',
          left: 'medium',
          right: 'medium',
          bottom: 'small',
        }}
      >
        <Text size="18px">Recipients</Text>
        <Box role="button" onClick={closeRecipientLayer} pad={{ top: 'xsmall' }}>
          <Close size="small" />
        </Box>
      </Box>
      <Divider color="brand" />
      <Box pad="small" style={{ maxHeight: '400px' }} overflow={{ vertical: 'auto' }}>
        <StyledTable>
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell scope="col" border="bottom">
                Name
              </StyledTableCell>
              <StyledTableCell scope="col" border="bottom">
                Email/Phone
              </StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <StyledTableBody>
            {logs.map((item, index) => (
              <TableRow key={index + 'recipient'}>
                <StyledTableCell scope="row">{item?.parent_name}</StyledTableCell>
                <StyledTableCell>{item?.sent_sms ? item?.parent_contact_no : item?.parent_email}</StyledTableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </StyledTable>
      </Box>
    </Layer>
  );
};

RecipientTable.propTypes = {
  logs: PropTypes.array,
  closeRecipientLayer: PropTypes.func,
};

export default RecipientTable;
