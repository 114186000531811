import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Layer, Text, TableRow, ThemeContext, Tabs, Tab, Button } from 'grommet';
import { Close, Chat } from 'grommet-icons';
import PropTypes from 'prop-types';
import EventEmitter from 'granite-admin/utils/event-emitter';
import Loader from 'granite-admin/core/components/Loader';
// import DatePicker from 'granite-admin/core/components/DatePicker';
import DatePicker from 'common/DatePicker';

import { fetchRouteMessageLogs } from 'common/TopNavbar/controllers/routeMessage';
import {
  StyledTable,
  StyledTableHeader,
  StyledTableBody,
  StyledTableCell,
} from 'common/TopNavbar/controllers/constants';
import MessageRoutes from './MessageRoutes';
import RecipientTable from './recipientsLayer';

import { customDateFormat, viewDateFormat } from 'common/CommonFunction';
import { getFormatedDate, convertDateToUTC } from 'common/CommonFunction';

const customTabs = {
  tab: {
    color: 'text',
    border: {
      hover: { color: 'secondary' },
    },
    active: {
      color: 'secondary',
    },
    pad: 'small',
    margin: { left: 'xsmall' },
    hover: {
      color: 'secondary',
    },
  },
};

const RouteMessageLayer = ({ setRouteMessage, boxLabel }) => {
  const userPreferenceData = useSelector(({ accounts }) => accounts.user.profile);
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const [isLoading, setIsLoading] = useState(true);
  const [messageLogs, setMessageLogs] = useState([]);
  // const [showRecipient, setShowRecipient] = useState(null);
  const [recipientLayer, setRecipientLayer] = useState(null);
  const todayDate = useMemo(
    () => getFormatedDate(new Date(), userPreferenceData?.userTimezone),
    [userPreferenceData?.userTimezone],
  );
  // let tomorrow = new Date(todayDate);
  // tomorrow.setDate(tomorrow.getDate() + 1);
  // const tomorrowDate = useMemo(() => tomorrow.toISOString().slice(0, 10), []);

  const [fromToDate, setFromToDate] = useState({ to_date: todayDate, from_date: todayDate });
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'FETCH_LOGS_SUCCESS':
          setMessageLogs(event?.data);
          setIsLoading(false);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter]);

  useEffect(() => {
    const UTCFromDate = convertDateToUTC(`${fromToDate?.from_date}T00:00:00`, userPreferenceData?.userTimezone);
    const UTCToDate = convertDateToUTC(`${fromToDate?.to_date}T23:59:00`, userPreferenceData?.userTimezone);
    fetchRouteMessageLogs(eventEmitter, { from_date: UTCFromDate, to_date: UTCToDate });
  }, [eventEmitter, fromToDate, userPreferenceData.userTimezone]);

  const handleShowRecipient = useCallback(e => {
    const logsArr = JSON.parse(e.target.getAttribute('data-logs') || {});
    setRecipientLayer(logsArr);
  }, []);

  const fetchLogsRecipients = useCallback(logs => {
    let endPoint = 4;
    let str = [];
    for (let i = 0; i < endPoint; i++) {
      str.push(
        <Text as="div" key={i}>
          {logs?.recipients[i]?.sent_sms
            ? logs?.recipients?.[i]?.parent_contact_no
            : logs?.recipients?.[i]?.parent_email}
          {i === endPoint - 1 && !!logs.recipients?.length && ' ....'}
        </Text>,
      );
    }
    return (
      <Box fill style={{ maxHeight: '80px' }} overflow={{ vertical: 'auto' }}>
        {str}
      </Box>
    );
  }, []);

  const closeRecipientLayer = useCallback(() => {
    setRecipientLayer(null);
  }, []);

  const fetchDateTime = useCallback((datetime, type = 'date') => {
    const [date, time, zone] = datetime.split(' ');
    return type === 'date' ? date : time + ' ' + zone;
  }, []);

  const handleFromChange = useCallback(
    value => {
      setFromToDate(prev => ({ ...prev, from_date: value }));
      try {
        const to_date = new Date(fromToDate?.to_date);
        if (value && new Date(value).getTime() > to_date.getTime()) {
          setFromToDate(prev => ({ ...prev, to_date: value }));
        }
      } catch (e) {
        return;
      }
    },
    [fromToDate?.to_date],
  );

  const handleToChange = useCallback(
    value => {
      setFromToDate(prev => ({ ...prev, to_date: value }));
      try {
        const from_date = new Date(fromToDate?.from_date);
        if (value && new Date(value).getTime() < from_date.getTime()) {
          setFromToDate(prev => ({ ...prev, from_date: value }));
        }
      } catch (e) {
        return;
      }
    },
    [fromToDate?.from_date],
  );

  const handleRouteMsgClose = useCallback(() => {
    setRouteMessage(false);
  }, [setRouteMessage]);

  return (
    <>
      <Layer onClickOutside={handleRouteMsgClose} onEsc={handleRouteMsgClose}>
        <Box gap="small" width="81rem">
          <Box
            justify="between"
            direction="row"
            pad={{
              top: 'medium',
              left: 'medium',
              right: 'medium',
              bottom: 'small',
            }}
          >
            <Box direction="row" gap="small" align="center">
              <Chat color="#118911" size="small" />
              <Text size="18px">Messaging Hub</Text>
            </Box>
            <Box onClick={handleRouteMsgClose} pad={{ top: 'xsmall' }}>
              <Close size="small" />
            </Box>
          </Box>
          <Box overflow={{ vertical: 'auto' }} pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box pad={{ bottom: 'small' }} flex={{ shrink: 0 }}>
              <Text>
                The RollCall messaging hub is designed to make communicating with your students parents simple, from
                designing welcome emails, to messaging all the parents with students on certain buses.{' '}
              </Text>
            </Box>
            <ThemeContext.Extend value={customTabs}>
              <Tabs justify="start">
                <Tab title="MESSAGE ROUTES">
                  <Box pad="small" background="#86868629">
                    <MessageRoutes
                      eventEmitter={eventEmitter}
                      setRouteMessage={setRouteMessage}
                      userPreferenceData={userPreferenceData}
                    />
                  </Box>
                </Tab>
                <Tab title="MESSAGE LOGS">
                  <Box pad="small">
                    <Box direction="row" gap="medium" alignSelf="end" margin={{ bottom: 'medium' }}>
                      <Box>
                        <Text>From Date</Text>
                        <DatePicker
                          name="fromDate"
                          initialDate={fromToDate.from_date}
                          onChange={handleFromChange}
                          customFormat={customDateFormat}
                          viewDateFormat={viewDateFormat}
                          customTimeZone={userPreferenceData?.userTimezone}
                        />
                      </Box>
                      <Box>
                        <Text>To Date</Text>
                        <DatePicker
                          name="toDate"
                          initialDate={fromToDate.to_date}
                          onChange={handleToChange}
                          customFormat={customDateFormat}
                          viewDateFormat={viewDateFormat}
                          customTimeZone={userPreferenceData?.userTimezone}
                        />
                      </Box>
                    </Box>
                    {isLoading ? (
                      <Box align="center" justify="center" fill>
                        <Loader size="20px" />
                      </Box>
                    ) : messageLogs.length ? (
                      <Box overflow="auto">
                        <StyledTable>
                          <StyledTableHeader>
                            <TableRow>
                              <StyledTableCell scope="col" width="40px">
                                Date
                              </StyledTableCell>
                              <StyledTableCell scope="col" width="40px">
                                Time
                              </StyledTableCell>
                              <StyledTableCell scope="col">Route</StyledTableCell>
                              <StyledTableCell scope="col">Selected Route Date</StyledTableCell>
                              <StyledTableCell scope="col">Author</StyledTableCell>
                              <StyledTableCell scope="col" width="100px">
                                Send options
                              </StyledTableCell>
                              <StyledTableCell scope="col" width="120px">
                                Message
                              </StyledTableCell>
                              <StyledTableCell scope="col" width="40px">
                                No. of affected students
                              </StyledTableCell>
                              <StyledTableCell scope="col" width="180px">
                                Recipients sent to
                              </StyledTableCell>
                              <StyledTableCell scope="col" width="40px">
                                See all email/phone recipients
                              </StyledTableCell>
                            </TableRow>
                          </StyledTableHeader>
                          <StyledTableBody>
                            {messageLogs &&
                              messageLogs.map((logs, index) => (
                                <TableRow key={index}>
                                  <StyledTableCell width="40px" scope="col">
                                    {fetchDateTime(logs.messageDatetime)}
                                  </StyledTableCell>
                                  <StyledTableCell scope="col" width="40px" style={{ whiteSpace: 'nowrap' }}>
                                    {fetchDateTime(logs.messageDatetime, 'time')}
                                  </StyledTableCell>
                                  <StyledTableCell scope="col">{logs.route}</StyledTableCell>
                                  <StyledTableCell scope="col">{logs.busDate}</StyledTableCell>
                                  <StyledTableCell scope="col">{logs.sentBy}</StyledTableCell>
                                  <StyledTableCell scope="col" width="100px">
                                    {logs.sendOptions}
                                  </StyledTableCell>
                                  <StyledTableCell scope="col" width="120px" style={{ overflowWrap: 'break-word' }}>
                                    {logs.message}
                                  </StyledTableCell>
                                  <StyledTableCell scope="col" width="40px">
                                    {logs.affectedStudents}
                                  </StyledTableCell>
                                  <StyledTableCell scope="col" width="180px">
                                    {fetchLogsRecipients(logs)}
                                  </StyledTableCell>
                                  <StyledTableCell scope="col" width="40px">
                                    <Button
                                      primary
                                      margin="auto"
                                      size="small"
                                      data-logs={JSON.stringify(logs.recipients)}
                                      label="Show"
                                      onClick={handleShowRecipient}
                                    />
                                  </StyledTableCell>
                                </TableRow>
                              ))}
                          </StyledTableBody>
                        </StyledTable>
                      </Box>
                    ) : (
                      <Box align="center">
                        <Text> No Records Found.</Text>
                      </Box>
                    )}
                  </Box>
                </Tab>
              </Tabs>
            </ThemeContext.Extend>
          </Box>
        </Box>
      </Layer>
      {recipientLayer && <RecipientTable closeRecipientLayer={closeRecipientLayer} logs={recipientLayer} />}
    </>
  );
};

RouteMessageLayer.propTypes = {
  setRouteMessage: PropTypes.func,
  boxLabel: PropTypes.any,
};

export default RouteMessageLayer;
