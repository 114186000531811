class EventGroups {
  constructor(data) {
    this.pk = data?.pk;
    this.groupName = data?.group_name;
    this.groupCode = data?.group_code;
    this.inPortal = data?.in_portal;
    this.pmDisplay = data?.pm_display;
    this.amDisplay = data?.am_display;
    this.schoolOffCheck = data?.school_off_check;
    this.schoolCheck = data?.school_check;
    this.supervisoroffchecks = data?.supervisor_off_check;
    this.supervisorchecks = data?.supervisor_check;
    this.busoffchecks = data?.bus_off_check;
    this.busCheck = data?.bus_check;
    this.campusStopCheck = data?.campus_stop_check;
    this.showAllStops = data?.show_all_stops;
    this.restrictmultiple = data?.one_student_one_route;
    this.routeCount = data?.route_count;
    this.availableRoute = data?.available_route;
    this.supervisorCheck = data?.supervisor_check;
    this.allDay = data?.all_day;
  }
}

export default EventGroups;