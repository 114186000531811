import React, {useMemo} from "react";
import { Box, Card, Image } from 'grommet';
import LoginForm from './components/loginform';
import EventEmitter from 'granite-admin/utils/event-emitter';

const AdminForget =()=>{
    const eventEmitter = useMemo(() => new EventEmitter(), []);
    return (
        <Card pad= 'xxsmall' width='16%' alignSelf='center' style={{margin:'100px'}}> 
            <Box width="72%" alignSelf='center' margin={{ vertical: 'medium' }} flex={{ shrink: 0 }}>
                 <Image src={'https://school1.testing.rollcall.com.au/client/image/rollcall.png'} fit="contain" />
            </Box>
            <LoginForm eventEmitter={eventEmitter}/>
        </Card>
    );
};
export default AdminForget;