import React, { useMemo, useEffect } from 'react';
import { Box, Button } from 'grommet';
import PropTypes from 'prop-types';

import { getGrandEmitter } from 'granite-admin/utils/grandEmitter';
import RouteMessageLayer from './RouteMessageLayer';

const dStyle = {
  width: '110px',
  padding: '0.25rem 0.75rem',
};

const RightItems = ({ item, theme, setOpenSupport, setRouteMessage, routeMessage }) => {
  const grandEmitter = useMemo(() => getGrandEmitter(), []);

  useEffect(() => {
    const subscription = grandEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case 'MARKETPLACE':
          window.open('https://www.rollcallmarketplace.com.au', '_blank', 'noopener,noreferrer');
          break;
        case 'SUPPORT':
          setOpenSupport(true);
          // window.FreshworksWidget("show");
          break;
        case 'MESSAGING':
          setRouteMessage(true);
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [grandEmitter, setOpenSupport, setRouteMessage]);

  return (
    <>
      <Box
        focusIndicator={false}
        style={{ whiteSpace: 'nowrap' }}
        pad={{
          vertical: 'xsmall',
          horizontal: 'small',
        }}
        onClick={() => {
          grandEmitter.emit(item.extra_data.emitter, { message: item.extra_data.message });
        }}
        as="div"
      >
        {item?.extra_data?.showIcon ? (
          <Button
            size="small"
            style={{ padding: '0.30rem 0 0 0' }}
            icon={
              item.icon && typeof item.icon === 'string' ? (
                <span
                  style={{ display: 'flex' }}
                  color={item?.extra_data?.btncolor}
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                />
              ) : (
                item.icon
              )
            }
          />
        ) : (
          <Button
            style={{ ...dStyle, ...theme?.topNavBar?.topRightItemStyle }}
            theme={theme}
            label={item.label}
            alignSelf="center"
            margin={{ vertical: 'small' }}
            color={item?.extra_data?.btncolor}
            size="small"
            gap="xxsmall"
            justify="center"
            icon={
              item.icon && typeof item.icon === 'string' ? (
                <span
                  style={{
                    display: 'flex',
                    marginTop: item.label === 'Messaging' ? '1px' : '',
                    paddingRight: item.label === 'Messaging' ? '2px' : '',
                  }}
                  color={item?.extra_data?.btncolor}
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                />
              ) : (
                item.icon
              )
            }
            primary
          />
        )}
      </Box>
      {routeMessage && item?.name === 'messaging' && <RouteMessageLayer setRouteMessage={setRouteMessage} />}
    </>
  );
};

RightItems.propTypes = {
  item: PropTypes.array,
  theme: PropTypes.object,
  setOpenSupport: PropTypes.func,
  setRouteMessage: PropTypes.func,
};

export default RightItems;
