// Core Imports
import GraniteAPIClient from 'granite-admin/utils/granite-client';

// Application Imports
import EventTypeEntity from 'eventGroups/entities/eventGroups';
import GraniteError from 'granite-admin/utils/granite-error';
import { env } from 'granite-admin/env';

/* eslint-disable */
const url = env.REACT_APP_API_BASE_URL + env.REACT_APP_ROLLCALL_API;
// const url = process.env.REACT_APP_ROLLCALL_BASE_URL;

const mapToSnakeCase = data => {
  return {
    group_name: data.name ? data.name : null,
    check_data: { [data.key]: data.value },
  };
};

async function getEventGroup(params) {
  try {
    const payload = { params: { ...params, ordering: params?.ordering === null ? 'group_name' : params?.ordering } };
    const { data } = await GraniteAPIClient(url).get('/route-group/', payload);
    const list = data ? data.results?.map(item => new EventTypeEntity(item)) : [];
    return { list, count: data.count };
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function updateEventGroup(eventgroup) {
  try {
    const payload = mapToSnakeCase(eventgroup);
    if (eventgroup.pk && eventgroup.name) {
      return await GraniteAPIClient(url).put(`/route-group/${eventgroup.pk}/`, payload);
    } else if (eventgroup.pk) {
      return await GraniteAPIClient(url).patch(`/change-check-route-group/${eventgroup.pk}/`, payload);
    } else {
      return await GraniteAPIClient(url).post('/route-group/', payload);
    }
  } catch (err) {
    throw new GraniteError(err);
  }
}

async function deleteEventGroup(eventgroupId) {
  try {
    return await GraniteAPIClient(url).delete(`/route-group/${eventgroupId}/`);
  } catch (err) {
    throw new GraniteError(err);
  }
}

export default { getEventGroup, deleteEventGroup, updateEventGroup };
