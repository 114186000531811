import React from 'react';
import {Box, Text, TextInput, Paragraph, Button} from 'grommet';
import FormField from 'granite-admin/core/components/FormField';
import * as Yup from 'yup';
import LOGIN_MESSAGES from 'granite-admin/messages/login';
import { Formik } from 'formik';

const LoginForm = (eventEmitter, loginClicked) => {
    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .min(2, LOGIN_MESSAGES.EMAIL_TOO_SHORT)
          .max(50, LOGIN_MESSAGES.EMAIL_TOO_LONG)
          .email(LOGIN_MESSAGES.EMAIL_INVALID)
          .required(LOGIN_MESSAGES.EMAIL_REQUIRED),
      });
    const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
        await loginClicked(eventEmitter, values);
    } catch (e) {
        if (e.errors) setErrors(e.errors);
    }
    setSubmitting(false);
    };
    return(
        <Formik
        initialValues={{
            email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
            return (
            <Box pad={{ horizontal:'large', vertical: 'medium'}}>
            <form onSubmit={handleSubmit}>
            <Text as="div" color="status-critical">
                {errors.title}
            </Text>
            <Paragraph>Please enter your email to reset password.</Paragraph>
            <FormField name="email" label={LOGIN_MESSAGES.EMAIL_LABEL} error={touched.email && errors.email}>
                <TextInput type="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
            </FormField>
            <Box>
                <Button
                    type="submit"
                    style={{width:'100%', padding: '14px 0', fontSize: 'large'}}
                    alignSelf="start"
                    // size='xxlarge'
                    margin={{ top: 'medium' }}
                    label={isSubmitting ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING : 'Reset Password'}
                    disabled={isSubmitting}
                    primary
                    color="accent-1"
                />
            </Box>
            </form>
        </Box>
        )
        }}
      </Formik>
    );
};
export default LoginForm;