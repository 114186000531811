import { connect, useSelector } from 'react-redux';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Box, Text, DropButton, Image } from 'grommet';
import { FormDown } from 'grommet-icons';
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';

import * as OrganisationDucks from 'granite-admin/organisations/ducks/organisations';
import organisationApi from 'dashlets/gateways/dashlets-api';
import { getTenantAccess } from 'granite-admin/utils/auth-singleton';
import * as UserDucks from 'granite-admin/accounts/ducks/user';
import useThemeConfigs from 'granite-admin/utils/useThemeConfigs';
import { getInitialValues } from 'granite-admin/pages/themeConfigs/utils';
import { ConfigContext } from 'granite-admin/core/components/ConfigProvider';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';
import { getCookie, deleteCookie, setCookie } from 'granite-admin/utils/storage';
import { useToast } from 'granite-admin/core/components/Toast';

const StyledBox = styled(Box)`
  min-width: 30px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledDropBox = styled(Box)`
  padding: 0.5rem;
  flex-shrink: 0;
`;

const StyledDropButton = styled(DropButton)`
  &:hover {
    background: none;
    border-bottom: ${({ theme }) => `1px solid ` + theme.global.colors['sidebar-svg-active'] + `!important`};
  }
`;

const BAOptions = ({ bAOrganisation, selectedOrganisation, theme, onSelect }) => {
  return (
    <Box gap="small" height={{ max: 'large' }}>
      {bAOrganisation.length > 0 ? (
        bAOrganisation
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          .filter(org => org.pk !== selectedOrganisation.pk)
          .map(i => (
            <StyledDropBox key={i.pk} className="LeftNavItem" onClick={() => onSelect(i)} focusIndicator={false}>
              <Text>{i.name}</Text>
            </StyledDropBox>
          ))
      ) : (
        <StyledDropBox align="center" hoverColor={theme.global.colors.dropButtonHover}>
          <Text size="medium"> No Schools</Text>
        </StyledDropBox>
      )}
    </Box>
  );
};

const Options = ({ organisations, onSelect, theme, selectedOrganisation }) => {
  return (
    <Box gap="small" height={{ max: 'large' }}>
      {organisations.length > 0 ? (
        organisations
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          .filter(org => org.pk !== selectedOrganisation.pk)
          .map(i => (
            <StyledDropBox key={i.pk} className="LeftNavItem" onClick={() => onSelect(i)} focusIndicator={false}>
              <Text>{i.name}</Text>
            </StyledDropBox>
          ))
      ) : (
        <StyledDropBox align="center" hoverColor={theme.global.colors.dropButtonHover}>
          <Text size="medium"> No Schools</Text>
        </StyledDropBox>
      )}
    </Box>
  );
};

const SchoolLogoDropdown = ({ theme, dispatch, organisations, selectedOrganisation, userProfile }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOrganisation?.name);
  const [bAOrganisation, setBAOrganisation] = useState([]);
  const [logoEnabled, setLogoEnabled] = useState('');
  const { getThemeConfigs } = useThemeConfigs();
  const themeConfigsValues = getInitialValues(getThemeConfigs);
  const hasTenantAccess = getTenantAccess();
  const config = useContext(ConfigContext);
  const { errorToast } = useToast();
  const userPreferenceData = useSelector(({ accounts }) => accounts.user.profile);

  const getOrganisations = useCallback(async () => {
    try {
      const params = {
        page: 1,
        page_size: 10000,
        is_active: true,
        exclude_industry: 'Konect',
        fetch_organisation_plan: true,
        ordering: 'name',
      };
      const result = await organisationApi.getParticularOrganisations({
        ...params,
      });
      setBAOrganisation(result?.list);
    } catch (error) {
      console.log('error');
      errorToast(error?.errors?.title || 'Unable to fetch organizations');
    }
  }, [errorToast]);

  useEffect(() => {
    if (userProfile.isSystemAdmin && selectedOrganisation.pk && !hasTenantAccess) {
      getOrganisations();
    }
  }, [getOrganisations, hasTenantAccess, selectedOrganisation.pk, userProfile.isSystemAdmin]);

  const switchOrganisation = useCallback(
    organisation => {
      const currentSubdomain = window.location?.host.split('.')[0];
      deleteCookie('organisationData');
      deleteCookie('BAorigin');
      deleteCookie('redirectDomain');
      if (config?.subdomainRedirectEnabled && organisation?.domain && organisation?.domain !== currentSubdomain) {
        const host = window.location.host;
        const authToken = getAuthToken();
        const domain = host.substring(
          host.indexOf('.') + 1,
          host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
        );
        setCookie('nodirectURL', true, 1, domain);
        setCookie('organisationData', JSON.stringify(organisation), 1, domain);
        setCookie('BAorigin', window.origin, 1, domain);
        setCookie('redirectDomain', 'yes', 1, domain);
        console.log('inside localStorage');
        // localStorage.clear();
        if (bAOrganisation?.length > 0) {
          window.location = `${window.location.protocol}//${organisation.domain}.${domain}${
            window.location.port ? `:${window.location.port}` : ''
          }/login?access_token=${authToken}&redirectDomain=yes`;
        } else {
          window.location = `${window.location.protocol}//${organisation.domain}.${domain}${
            window.location.port ? `:${window.location.port}` : ''
          }/login?access_token=${authToken}&redirectDomain=yes&tenantAccess=true`;
        }
      } else if (config.subdomainRedirectEnabled && getCookie('redirectDomain') === 'yes') {
        const URL = `${getCookie('BAorigin')}/login`;
        const host = window.location.host;
        const domain = host.substring(
          host.indexOf('.') + 1,
          host.length - (window.location.port.length + (window.location.port.length ? 1 : 0)),
        );
        setCookie('nodirectURL', true, 1, domain);
        setCookie('organisationData', '', 1, domain);
        setCookie('BAorigin', '', 1, domain);
        setCookie('redirectDomain', '', 1, domain);
        window.location = URL;
      } else {
        dispatch({
          type: 'organisations/UPDATE_SELECTED_ORGANISATION',
          data: organisation,
        });
        dispatch({
          type: 'accounts/sidebar/UPDATE_SIDEBAR',
          data: [],
        });
        window.location.href = '/dashboard';
      }
    },
    [config.subdomainRedirectEnabled, bAOrganisation.length, dispatch],
  );

  const onSelect = useCallback(
    selectedItem => {
      setSelected(selectedItem.name);
      switchOrganisation(selectedItem);
      setOpen(false);
    },
    [switchOrganisation],
  );
  useEffect(() => {
    setLogoEnabled(userPreferenceData?.isLogoEnabled);
  }, [userPreferenceData]);

  return (
    <>
      <StyledDropButton
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        dropProps={{ align: { top: 'bottom' } }}
        dropContent={
          userProfile.isSystemAdmin && selectedOrganisation.pk && !hasTenantAccess ? (
            <BAOptions
              bAOrganisation={bAOrganisation}
              onSelect={onSelect}
              selectedOrganisation={selectedOrganisation}
              theme={theme}
            />
          ) : (
            <Options
              organisations={organisations}
              onSelect={onSelect}
              theme={theme}
              selectedOrganisation={selectedOrganisation}
            />
          )
        }
        focusIndicator={false}
      >
        <StyledBox direction="row" align="center" pad="0.5rem" gap="small" justify="between">
          {logoEnabled === 'true' ? (
            <Box direction="row" gap="small" height="40px" align="center" justify="center">
              <Box height="100%">
                <Image src={themeConfigsValues?.logo} alt="School Logo" height="100%" />
              </Box>
              <Text>{selected}</Text>
            </Box>
          ) : (
            <Text>{selected}</Text>
          )}
          <FormDown size="small" color="brand" />
        </StyledBox>
      </StyledDropButton>
    </>
  );
};

SchoolLogoDropdown.propTypes = {
  theme: PropTypes.any,
  dispatch: PropTypes.func,
  organisations: PropTypes.array,
  selectedOrganisation: PropTypes.object,
  userProfile: PropTypes.object,
};

Options.propTypes = {
  organisations: PropTypes.array,
  onSelect: PropTypes.func,
  theme: PropTypes.any,
  selectedOrganisation: PropTypes.object,
};

BAOptions.propTypes = {
  onSelect: PropTypes.func,
  theme: PropTypes.any,
  selectedOrganisation: PropTypes.object,
  bAOrganisation: PropTypes.array,
};

const mapStateToProps = state => ({
  selectedOrganisation: OrganisationDucks.selectedOrganisation(state),
  organisations: OrganisationDucks.organisations(state),
  userProfile: UserDucks.profile(state),
});

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SchoolLogoDropdown));
