import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Text, Layer, Button } from 'grommet';
import { Close } from 'grommet-icons';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import Divider from 'granite-admin/core/components/Divider';
import userAPI from 'granite-admin/accounts/gateways/user-api';
import { useToast } from 'granite-admin/core/components/Toast';
import EventEmitter from 'granite-admin/utils/event-emitter';
// import { getMasterPreferences } from 'granite-admin/accounts/controllers/user.js';

import accountsUserAPI from 'accounts/gateways/user_api';

import {
  getUserPreferences,
  getMasterPreferences,
  changePhone,
  verifyPhoneChange,
  // getMyProfile,
  // verifyEmailChange,
} from 'granite-admin/accounts/controllers/user.js';

import SelectFAType from './components/SelectFAType';
import SelectFADetails from './components/SelectFADetails';
import SuccessScreen from './components/SuccessScreen';
import { changeUserPreference, checkDuplicatePhone } from 'accounts/controllers/user';
import { RESET_EVENTS } from 'accounts/controllers/constants';
// import tokenAPI from 'accounts/gateways/user_api'
import _ from 'lodash';

const StyledClose = styled(Close)`
  &:hover {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const textWarningColor = '#ff0000';

const TwoFALayer = ({
  setShow2FALayer,
  userProfile,
  fetchUserProfile,
  dispatch,
  getSavedThemeConfigs,
  defaultThemeConfigs,
}) => {
  const [steps, setSteps] = useState(0);
  const [otpSent, setOtpSent] = useState(false);

  const [configs, setConfigs] = useState([]);
  const [preferences, setPreferences] = useState([]);
  const [changePhoneLayer, setChangePhoneLayer] = useState();
  const [phoneNotExist, setPhoneNotExist] = useState(false);
  const [duplicatePhone, setDuplicatePhone] = useState(true);

  const formikRef = useRef();
  const eventEmitter = useMemo(() => new EventEmitter(), []);
  const { successToast, errorToast } = useToast();

  useEffect(() => {
    async function fetch() {
      try {
        const result = await Promise.all([getMasterPreferences(), getUserPreferences(eventEmitter)]);
        setConfigs(result[0]);
        setPreferences(result[1]);
      } catch (err) {
        setConfigs([]);
        setPreferences([]);
      }
    }
    fetch();
  }, [eventEmitter, getMasterPreferences, getUserPreferences]);

  const updateFAOptionType = useCallback(
    async values => {
      try {
        let updatedValues = [
          {
            pk: preferences?.find(i => i.name === 'TWO_FA_OPTIONS')?.pk,
            value: values['type'] === 'sms' ? 'SMS' : values['type'] === 'email' ? 'EMAIL' : 'BOTH',
            name: 'TWO_FA_OPTIONS',
            masterpk: configs?.find(i => i.name === 'TWO_FA_OPTIONS')?.pk,
          },
        ];
        await changeUserPreference(eventEmitter, updatedValues);
        setPreferences(_.uniqBy([...updatedValues, ...preferences], 'pk'));
        setSteps(1);
      } catch (err) {
        errorToast(err?.errors?.title || 'Unable to update MFA medium');
      }
    },
    [setPreferences, eventEmitter, preferences, configs],
  );

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case RESET_EVENTS.FETCH_DUPLICATE_PHONE_STATUS:
          if (!event?.data?.duplicate_phone) updateFAOptionType(formikRef?.current?.values);
          else setSteps(1);
          setDuplicatePhone(event?.data?.duplicate_phone);
          break;
        case RESET_EVENTS.FAILURE_DUPLICATE_PHONE_STATUS:
          setDuplicatePhone(true);
          errorToast(event?.data || 'Something went wrong!');
          break;
        default:
      }
    });
    return () => subscription.unsubscribe();
  }, [eventEmitter, errorToast, updateFAOptionType]);

  const updatePhoneChange = async values => {
    if (formikRef?.current) formikRef.current.setSubmitting(true);
    try {
      await changePhone(eventEmitter, {
        password: values?.password,
        new_phone: { ...(values?.phone ?? {}) },
      });
      setChangePhoneLayer('OTP');
      if (formikRef?.current) formikRef.current.validateForm();
      successToast('OTP sent successfully');
      if (formikRef?.current?.setFieldValue) formikRef.current.setFieldValue('verifyingUpdatedPhone', true);
    } catch (e) {
      if (e?.errors) console.log('CHANGE PHONE ERR', e.errors);
      errorToast(e?.errors?.title || 'Unable to send OTP');
    } finally {
      if (formikRef?.current) formikRef.current.setSubmitting(false);
    }
  };

  const verifyPhoneOTPChange = async values => {
    if (formikRef?.current) formikRef.current.setSubmitting(true);

    try {
      await verifyPhoneChange(eventEmitter, { otp: values?.otp });

      fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs);
      setChangePhoneLayer(false);
      if (formikRef?.current) {
        formikRef.current.setFieldValue('isPhoneVerified', true);
        formikRef.current.submitForm();
      }
    } catch (e) {
      if (e.errors) console.log('ERR VERIFY PHONE', e.errors);
      errorToast(e?.errors?.title || 'Unable to verify OTP');
      if (formikRef?.current) formikRef.current.setSubmitting(false);
    }
  };

  const updateTwoFA = useCallback(
    async (val = 'true') => {
      let updatedValues = [
        {
          pk: preferences.find(i => i.name === 'is_2fa_enabled')?.pk,
          value: val,
          name: 'is_2fa_enabled',
          masterpk: configs?.find(i => i.name === 'is_2fa_enabled')?.pk,
        },
      ];

      await changeUserPreference(eventEmitter, updatedValues);
      setPreferences(_.uniqBy([...updatedValues, ...preferences], 'pk'));

      fetchUserProfile(dispatch, getSavedThemeConfigs, defaultThemeConfigs);
    },
    [changeUserPreference, setPreferences, eventEmitter, preferences, configs],
  );

  const handleCancel = useCallback(() => {
    setChangePhoneLayer(false);
    if (formikRef?.current) formikRef.current.resetForm();
    if (steps === 1) setSteps(0);
    else setShow2FALayer(false);
  }, [setShow2FALayer, steps, formikRef]);

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    setErrors({});
    setSubmitting(true);
    try {
      if (values?.type === 'email' && !values?.isEmailVerified) {
        if (otpSent) {
          //call verify OTP API
          // console.log('IF BLOCK 1');
          await userAPI.submitToken(values?.token, values?.email);
          //CALL 2FA API HERE
          updateTwoFA('true');
          setSteps(2);
        } else {
          //call send OTP API
          // console.log('IF BLOCK 2');

          await accountsUserAPI.resendOtp({ email: values?.email });
          setOtpSent(true);
        }
      } else if (values?.type === 'sms' && !values?.isPhoneVerified) {
        if (otpSent) {
          //call verify OTP API
          // console.log('IF BLOCK 3');
          await userAPI.submitToken(values?.token, values?.email, `+${values?.phone?.number}`);
          //CALL 2FA API HERE - updateTwoFA(values)
          updateTwoFA('true');
          setSteps(2);
        } else {
          //call send OTP API
          // console.log('IF BLOCK 4');
          await accountsUserAPI.resendOtp({ phone: `${values?.phone?.phone}` });
          setOtpSent(true);
        }
      } else {
        //check if 2FA is already enabled
        if (values?.twoFA === 'false') {
          //call 2FA change to true api
          // console.log('IF BLOCK 6');
          updateTwoFA('true');
          setSteps(2);
        }
      }
    } catch (e) {
      console.log('2FA Errors', e);
      if (e?.errors) {
        setErrors(e.errors);
        errorToast(e?.errors?.title || 'Unable to enable MFA');
      }
    }
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape({
    phone: Yup.object().when('type', {
      is: val => val === 'sms',
      then: Yup.object().test(value => {
        if (!value || value?.error) {
          return new Yup.ValidationError('Invalid phone number', undefined, 'phone');
        } else {
          return true;
        }
      }),
    }),
    email: Yup.string().when('type', {
      is: val => val === 'email',
      then: Yup.string().trim().email().required('New Email is required'),
    }),
    ...(changePhoneLayer === 'SMS'
      ? {
          password: Yup.string()
            .max(24, 'Password is too long')
            .matches(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
              'Password must contain at least 6 characters, one capital and one number',
            )
            .required('Current Password is required'),
        }
      : changePhoneLayer === 'OTP'
      ? { otp: Yup.string().max(6, 'Code is too Long').required('Code is required') }
      : {}),
    ...(otpSent ? { token: Yup.string().max(6, 'Code is too Long').required('Code is required') } : {}),
  });

  return (
    <Layer position="center" onEsc={handleCancel}>
      <Formik
        initialValues={{
          type: preferences?.find(p => p.name === 'TWO_FA_OPTIONS')?.value?.toLowerCase() || 'email',
          email: userProfile?.email || '',
          phone: userProfile?.phone?.number ? userProfile?.phone : { dialCode: '61', countryCode: 'au' },
          isPhoneVerified: userProfile?.isPhoneVerified,
          isEmailVerified: userProfile?.isEmailVerified,
          twoFA: userProfile?.twoFA,
          token: '',
          password: '',
          otp: '',
          verifyingUpdatedPhone: false,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        innerRef={formikRef}
        validationSchema={formikRef?.current ? validationSchema : {}}
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          submitForm,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          initialValues,
          isValid,
          isSubmitting,
          validateForm,
        }) => (
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Box flex={{ grow: 1, shrink: 0 }} width="500px" height={{ min: '30vh' }}>
              <>
                <Box pad={{ vertical: 'small', horizontal: 'medium' }} direction="row" justify="between" align="center">
                  <Text weight="bold" size="xlarge">
                    Multi-Factor Authentication Setup
                  </Text>

                  <Box align="end" pad={{ top: 'xsmall' }}>
                    <StyledClose onClick={() => setShow2FALayer(false)} size="20px" />
                  </Box>
                </Box>

                <Divider />
              </>
              <Box flex pad={{ vertical: 'small', horizontal: 'medium' }}>
                {steps === 0 ? (
                  <SelectFAType
                    values={values}
                    setFieldValue={setFieldValue}
                    setOtpSent={setOtpSent}
                    setPhoneNotExist={setPhoneNotExist}
                    phoneNotExist={phoneNotExist}
                    textWarningColor={textWarningColor}
                  />
                ) : steps === 1 ? (
                  <SelectFADetails
                    values={values}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    errors={errors}
                    otpSent={otpSent}
                    initialValues={initialValues}
                    changePhoneLayer={changePhoneLayer}
                    setChangePhoneLayer={setChangePhoneLayer}
                    updatePhoneChange={updatePhoneChange}
                    steps={steps}
                    validateForm={validateForm}
                    submitForm={submitForm}
                    isSubmitting={isSubmitting}
                    duplicatePhone={duplicatePhone}
                    textWarningColor={textWarningColor}
                  />
                ) : steps === 2 ? (
                  <SuccessScreen />
                ) : (
                  <></>
                )}
              </Box>
              <Box as="footer" gap="small" pad="small">
                <Divider />
                <Box fill="horizontal" justify="between" direction="row">
                  {steps !== 2 ? (
                    <Button label={steps === 1 ? 'Back' : 'Cancel'} primary onClick={handleCancel} />
                  ) : (
                    <Box></Box>
                  )}

                  <Button
                    label={
                      steps === 2
                        ? 'Continue'
                        : steps === 1 && (otpSent || (values?.type === 'sms' && changePhoneLayer === 'OTP'))
                        ? 'Verify Code'
                        : // : :  steps === 1 && values?.type === 'sms' && changePhoneLayer === 'SMS'
                          // ? 'Change Phone'
                          // steps === 1 && values?.type === 'sms' && changePhoneLayer === 'OTP'
                          // ? 'Verify Code'
                          // (steps === 1 && !otpSent && values?.type === 'sms' && !values?.isPhoneVerified) ||
                          //   (values?.type === 'email' && !values?.isEmailVerified)
                          // ? 'Send Code'
                          'Next'
                    }
                    primary
                    onClick={() => {
                      const { type, phone } = values;
                      if (steps === 0) {
                        if (type === 'sms') {
                          if (!phone?.number) {
                            setPhoneNotExist(true);
                          } else {
                            checkDuplicatePhone(eventEmitter, phone?.phone);
                          }
                        } else if (type === 'email') {
                          updateFAOptionType(values);
                        }
                      } else if (steps === 1) {
                        if (changePhoneLayer === 'OTP') {
                          verifyPhoneOTPChange(values);
                        } else {
                          submitForm();
                        }
                      } else if (steps === 2) {
                        setShow2FALayer(false);
                      }
                    }}
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      phoneNotExist ||
                      (steps === 0 && values?.type !== 'sms' && values?.type !== 'email') ||
                      (steps === 1 && changePhoneLayer === 'SMS') ||
                      (steps === 1 &&
                        !otpSent &&
                        !values?.verifyingUpdatedPhone &&
                        values?.type === 'sms' &&
                        !values?.isPhoneVerified) ||
                      (steps === 1 && !otpSent && values?.type === 'email' && !values?.isEmailVerified)
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Layer>
  );
};

TwoFALayer.propTypes = {
  setShow2FALayer: PropTypes.func,
  userProfile: PropTypes.object,
  fetchUserProfile: PropTypes.func,
  dispatch: PropTypes.func,
  getSavedThemeConfigs: PropTypes.func,
  defaultThemeConfigs: PropTypes.object,
};

export default TwoFALayer;
